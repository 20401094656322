import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AdditionalInformation } from "../ContractSpecs/AdditionalInformation";
import { ContractSpecsFilters } from "../ContractSpecsFilters";
import { JobBoardPagination } from "../JobBoard/JobBoardPagination/JobBoardPagination";
import { ContractSpecsTable } from "./ContractSpecsTable";
import { Container } from "../global";
import { ContractSpecsTabObj, getOrderedColumns, getDataPerAccountType, getSearchResults, groomInitFilterName, InstrumentFieldsTranslator, useExecuteTriggerCallback, getFiltersEmptyState } from "./utils";
import { CS_FIELDS_TRANSLATE_CONFIG, instrumentGroupsAndSubgroups, propertyReassignMap } from "./config";
import { IconNoResults } from "../svgIcons";
import { ImportantNote } from "./ImportantNote";
export const ContractSpecsTab = ({ group, subGroup, pageConfig, listOfPossibleFilters, data, culture, tableInfos, additionalInfos, swiperHandler, onToggleFiltersExpand, areFiltersExpanded, filtersTranslations, columnsTranslations }) => {
    const tabContextConfigInfo = useMemo(() => ({
        culture,
        instrumentGroupName: group,
        instrumentSubgroupName: subGroup,
        instrumentGroupsAndSubgroups,
        pageConfig
    }), [culture, group, pageConfig, subGroup]);
    const { t } = useTranslation();
    const TabObject = useMemo(() => new ContractSpecsTabObj(tabContextConfigInfo), [tabContextConfigInfo]);
    const groomedData = useMemo(() => TabObject.getGroomedData(data, propertyReassignMap), [TabObject, data]);
    const mounted = useRef(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 50;
    const allTabData = useRef([]);
    if (!mounted.current) {
        allTabData.current = TabObject.getRelevantData(groomedData);
    }
    const adjustedFilters = TabObject.getFilters();
    const defaultFilterSelectedValues = TabObject.getDefaultFilterValues();
    const adjustedFilterTranslations = TabObject.getTabFiltersTranslations(filtersTranslations);
    const [numOfPages, setNumberOfPages] = useState(Math.ceil(allTabData.current.length / itemsPerPage));
    const allPagesData = useRef(allTabData.current);
    const currentData = groomedData
        .filter((item) => item.instrumentGroup?.toLowerCase() ===
        subGroup?.toLowerCase() ||
        item.instrumentGroup?.toLowerCase() ===
            group.toLowerCase())
        .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    const tableInfo = tableInfos.filter(item => item.instrumentGroup?.toLowerCase() === subGroup?.toLowerCase() || item.instrumentGroup?.toLowerCase() === group.toLowerCase());
    const [filteredData, setFilteredData] = useState(currentData);
    const [filteredTableInfo, setFilteredTableInfo] = useState(tableInfo);
    const initialFiltersState = {
        searchText: "",
        accountType: "",
        instrumentSubtype: "",
        leverageType: "Retail",
        positionQuality: "",
        country: "",
        currency: ""
    };
    const [filtersState, setFiltersState] = useState(initialFiltersState);
    const updateFiltersState = (filterName, filterValue) => {
        setFiltersState((prevState) => ({
            ...prevState,
            [filterName]: filterValue
        }));
    };
    const [mustResetFiltersInternally, setMustResetFiltersInternally] = useState(false);
    const [filterDefaultsValues, setFilterDefaultValues] = useState(() => listOfPossibleFilters.reduce((accumulatedObject, currentFilterName) => {
        const keyName = groomInitFilterName(currentFilterName);
        return ({
            ...accumulatedObject,
            [keyName]: defaultFilterSelectedValues[keyName] ?? ""
        });
    }, {}));
    const currentFilterValuesSet = {
        accountType: filtersState.accountType,
        instrumentSubtype: filtersState.instrumentSubtype,
        leverageType: filtersState.leverageType,
        positionQuality: filtersState.positionQuality,
        country: filtersState.country,
        currency: filtersState.currency
    };
    const adjustFilterDefaulValues = (filterName, newDefaultValue) => {
        setFilterDefaultValues((prevValues) => ({
            ...prevValues,
            [filterName]: newDefaultValue
        }));
    };
    const handleDisplayedRows = useCallback((accountType, positionQuality, instrumentSubtype, country, currency, searchString) => {
        const dataPerAccountType = getDataPerAccountType({ searchString, accountType, instrumentGroup: group, positionQuality, instrumentSubtype, country, currency, culture }, allTabData.current);
        allPagesData.current = dataPerAccountType;
        setFilteredData(dataPerAccountType.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage));
    }, [culture, currentPage, group]);
    const handleDisplayedColumns = useCallback((accountType, instrumentSubtype, leverageType) => {
        setFilteredTableInfo(tableInfos.filter((item) => (item.instrumentGroup === group || item.instrumentGroup === instrumentSubtype || item.instrumentGroup === `${group} ${instrumentSubtype}`)
            && TabObject.checkColumnExistsForAccountType(item.accountType, accountType)
            && TabObject.checkIsCorrectLeverageColumn(item.dataColumnName, leverageType)));
    }, [TabObject, group, tableInfos]);
    const forceDisplayColumns = useCallback(() => {
        handleDisplayedColumns(filtersState.accountType, filtersState.instrumentSubtype, filtersState.leverageType);
    }, [filtersState.accountType, handleDisplayedColumns, filtersState.leverageType, filtersState.instrumentSubtype]);
    const handleSearchFilter = useCallback((query) => {
        setCurrentPage(0);
        const allFilteredData = getSearchResults(allTabData.current, query);
        allPagesData.current = allFilteredData;
        setFilteredData(allFilteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage));
        setNumberOfPages(Math.ceil(allFilteredData.length / itemsPerPage));
        updateFiltersState("searchText", query);
        swiperHandler();
    }, [currentPage, swiperHandler]);
    const handlePositionQualityFilter = useCallback((value, isDefaultValue = false) => {
        setCurrentPage(0);
        if (value === "All") {
            const dataPerAccountType = getDataPerAccountType({ accountType: filtersState.accountType, instrumentGroup: group, positionQuality: value, instrumentSubtype: filtersState.instrumentSubtype, country: filtersState.country, currency: filtersState.currency, culture }, allTabData.current);
            allPagesData.current = dataPerAccountType;
            setFilteredData(dataPerAccountType.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage));
            setNumberOfPages(Math.ceil(dataPerAccountType.length / itemsPerPage));
        }
        else {
            const allFilteredData = allTabData.current.filter((item) => item.positionQuality === value);
            const dataPerAccountType = getDataPerAccountType({ accountType: filtersState.accountType, instrumentGroup: group, positionQuality: value, instrumentSubtype: filtersState.instrumentSubtype, country: filtersState.country, currency: filtersState.currency, culture }, allFilteredData);
            allPagesData.current = dataPerAccountType;
            setFilteredData(dataPerAccountType.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage));
            setNumberOfPages(Math.ceil(dataPerAccountType.length / itemsPerPage));
        }
        updateFiltersState("positionQuality", value);
        isDefaultValue && adjustFilterDefaulValues("positionQuality", value);
        forceDisplayColumns();
        swiperHandler();
    }, [forceDisplayColumns, swiperHandler, filtersState.accountType, filtersState.instrumentSubtype, filtersState.country, filtersState.currency, group, culture, currentPage]);
    const handleCountryFilter = useCallback((country, isDefaultValue = false) => {
        setCurrentPage(0);
        const allFilteredData = country ? allTabData.current.filter((item) => item.country === country) : allTabData.current;
        allPagesData.current = allFilteredData;
        setFilteredData(allFilteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage));
        setNumberOfPages(Math.ceil(allFilteredData.length / itemsPerPage));
        updateFiltersState("country", country);
        isDefaultValue && adjustFilterDefaulValues("country", country);
        forceDisplayColumns();
        swiperHandler();
    }, [currentPage, forceDisplayColumns, swiperHandler]);
    const handleCurrencyFilter = useCallback((currency, isDefaultValue = false) => {
        setCurrentPage(0);
        const allFilteredData = currency ? allTabData.current.filter((item) => item.currency === currency) : allTabData.current;
        allPagesData.current = allFilteredData;
        setFilteredData(allFilteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage));
        setNumberOfPages(Math.ceil(allFilteredData.length / itemsPerPage));
        updateFiltersState("currency", currency);
        isDefaultValue && adjustFilterDefaulValues("currency", currency);
        forceDisplayColumns();
        swiperHandler();
    }, [currentPage, forceDisplayColumns, swiperHandler]);
    const handleAccountTypeFilter = useCallback((accountType, isDefaultValue = false) => {
        updateFiltersState("accountType", accountType);
        isDefaultValue && adjustFilterDefaulValues("accountType", accountType);
        handleDisplayedColumns(accountType, filtersState.instrumentSubtype, filtersState.leverageType);
        handleDisplayedRows(accountType, filtersState.positionQuality, filtersState.instrumentSubtype, filtersState.country, filtersState.currency, filtersState.searchText);
        swiperHandler();
    }, [filtersState.country, filtersState.currency, filtersState.positionQuality, filtersState.searchText, filtersState.instrumentSubtype, handleDisplayedColumns, handleDisplayedRows, filtersState.leverageType, swiperHandler]);
    const handleLeverageTypeFilter = useCallback((leverageType, isDefaultValue = false) => {
        updateFiltersState("leverageType", leverageType);
        isDefaultValue && adjustFilterDefaulValues("leverageType", leverageType);
        handleDisplayedColumns(filtersState.accountType, filtersState.instrumentSubtype, leverageType);
        swiperHandler();
    }, [filtersState.accountType, filtersState.instrumentSubtype, handleDisplayedColumns, swiperHandler]);
    const handleInstrumentSubtype = useCallback((instrumentSubtype, isDefaultValue = false) => {
        setCurrentPage(0);
        let allFilteredData = [];
        if (group === "Commodities") {
            allFilteredData = groomedData.filter((item => item.instrumentGroup?.includes(instrumentSubtype)));
        }
        else {
            allFilteredData = groomedData.filter((item => item.instrumentGroup?.includes(instrumentSubtype) && item.instrumentGroup?.includes(group)));
        }
        TabObject.setProps({
            instrumentSubgroup: instrumentSubtype
        });
        const adjustedFilteredData = TabObject.getRelevantData(allFilteredData);
        const dataPerAccountType = getDataPerAccountType({ accountType: filtersState.accountType, instrumentGroup: group, positionQuality: filtersState.positionQuality, instrumentSubtype, country: filtersState.country, currency: filtersState.currency, culture }, adjustedFilteredData);
        allTabData.current = adjustedFilteredData;
        allPagesData.current = dataPerAccountType;
        setFilteredData(dataPerAccountType.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage));
        setNumberOfPages(Math.ceil(dataPerAccountType.length / itemsPerPage));
        updateFiltersState("instrumentSubtype", instrumentSubtype);
        isDefaultValue && adjustFilterDefaulValues("instrumentSubtype", instrumentSubtype);
        handleDisplayedColumns(filtersState.accountType, instrumentSubtype, filtersState.leverageType);
        swiperHandler();
    }, [group, TabObject, filtersState.accountType, filtersState.positionQuality, filtersState.country, filtersState.currency, filtersState.leverageType, culture, currentPage, handleDisplayedColumns, swiperHandler, groomedData]);
    const handlerFunctions = useMemo(() => ({
        positionQuality: handlePositionQualityFilter,
        country: handleCountryFilter,
        currency: handleCurrencyFilter,
        accountType: handleAccountTypeFilter,
        leverageType: handleLeverageTypeFilter,
        instrumentSubtype: handleInstrumentSubtype
    }), [handleAccountTypeFilter, handleCountryFilter, handleCurrencyFilter, handleLeverageTypeFilter, handlePositionQualityFilter, handleInstrumentSubtype]);
    const autoExecuteRelevantHandlers = useCallback(() => {
        const handlersToExecute = TabObject.getAutoExecutedHandlers();
        Object.entries(handlersToExecute)?.forEach(([handlerKey, handlerValue]) => {
            if (typeof handlerValue === "undefined") {
                return;
            }
            handlerFunctions[handlerKey]?.(handlerValue, true);
        });
    }, [TabObject, handlerFunctions]);
    const handleResetAllFilters = useCallback(() => {
        autoExecuteRelevantHandlers();
        setMustResetFiltersInternally(true);
    }, [autoExecuteRelevantHandlers]);
    useEffect(() => {
        if (mustResetFiltersInternally) {
            setMustResetFiltersInternally(false);
        }
    }, [mustResetFiltersInternally]);
    const tabElementRef = useRef(null);
    const scrollToТabElementRef = useCallback(() => {
        const filtersElementCoordY = tabElementRef.current?.getBoundingClientRect().top;
        const headerEl = document.body.querySelector("header") || undefined;
        const headerHeight = headerEl?.getBoundingClientRect().height;
        if (typeof filtersElementCoordY !== "undefined" && typeof headerHeight !== "undefined") {
            const scrollToY = window.scrollY + filtersElementCoordY - headerHeight;
            window.scroll(0, scrollToY);
        }
    }, []);
    const checkAreDefaultFilterValues = () => {
        for (const filterName in filterDefaultsValues) {
            if (Object.prototype.hasOwnProperty.call(filterDefaultsValues, filterName)) {
                if (filterDefaultsValues[filterName] !== currentFilterValuesSet[filterName]) {
                    return false;
                }
            }
        }
        return true;
    };
    useEffect(() => {
        setFilteredData(allPagesData.current.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage));
        swiperHandler();
    }, [currentPage, swiperHandler]);
    useEffect(() => {
        if (mounted.current) {
            scrollToТabElementRef();
        }
        else {
            mounted.current = true;
        }
    }, [currentPage, scrollToТabElementRef]);
    const haveAutoHandlersBeenExecuted = useRef(false);
    useEffect(() => {
        if (haveAutoHandlersBeenExecuted.current) {
            return;
        }
        autoExecuteRelevantHandlers();
        haveAutoHandlersBeenExecuted.current = true;
    }, [autoExecuteRelevantHandlers]);
    useEffect(() => {
        handleDisplayedColumns(filtersState.accountType, filtersState.instrumentSubtype, filtersState.leverageType);
        handleDisplayedRows(filtersState.accountType, filtersState.positionQuality, filtersState.instrumentSubtype, filtersState.country, filtersState.currency, filtersState.searchText);
    }, [filtersState.accountType, filtersState.country, filtersState.currency, handleDisplayedColumns, handleDisplayedRows, filtersState.leverageType, filtersState.positionQuality, filtersState.searchText, filtersState.instrumentSubtype]);
    useEffect(() => {
        setNumberOfPages(Math.ceil(allPagesData.current.length / itemsPerPage));
    }, [allPagesData.current.length]);
    const orderedTableInfo = getOrderedColumns(filteredTableInfo);
    const getLocalInfoKey = () => filtersState.instrumentSubtype || subGroup || group;
    const additionalInfo = TabObject.getAdditionaInfo(getLocalInfoKey(), additionalInfos);
    const instrFieldTranslator = new InstrumentFieldsTranslator({ culture, instrumentGroupName: group, instrumentSubgroupName: subGroup, instrFieldsTranslateConfig: CS_FIELDS_TRANSLATE_CONFIG, translationFunction: t });
    const { triggerRefresh } = useExecuteTriggerCallback(() => {
        swiperHandler();
    });
    const handleFiltersTransition = React.useCallback(() => {
        triggerRefresh();
    }, [triggerRefresh]);
    const filtersEmptyState = getFiltersEmptyState(adjustedFilters);
    const mustShowImportantNote = !filtersEmptyState?.nonEmptyList.includes("leverageTypes");
    return (React.createElement("div", { ref: tabElementRef, className: "contract-specs-tab" },
        React.createElement(Container, null,
            React.createElement(ImportantNote, { mustShowImportantNote: mustShowImportantNote, text: additionalInfo?.importantNote, className: "contract-specs-tab__important-note" }),
            React.createElement(ContractSpecsFilters, { ...adjustedFilters, ...defaultFilterSelectedValues, filtersEmptyState: filtersEmptyState, onSearchChange: handleSearchFilter, onPositionQualityChange: handlePositionQualityFilter, onCountryChange: handleCountryFilter, onCurrencyChange: handleCurrencyFilter, onAccountTypeChange: handleAccountTypeFilter, onLeverageTypeChange: handleLeverageTypeFilter, onInstrumentSubtypeChange: handleInstrumentSubtype, onToggleFiltersExpand: onToggleFiltersExpand, onFiltersTransitionEnd: handleFiltersTransition, onResetAllFilters: handleResetAllFilters, mustResetFiltersInternally: mustResetFiltersInternally, areFiltersExpanded: areFiltersExpanded, showClearFiltersButton: !checkAreDefaultFilterValues(), filtersTranslations: adjustedFilterTranslations }),
            numOfPages > 0 ?
                React.createElement("div", { className: "price-feed-cs__table-container" },
                    React.createElement(ContractSpecsTable, { data: filteredData, columnInfo: TabObject.getEnhancedTableInfo(orderedTableInfo, filtersState.leverageType), className: `price-feed-cs__table--${group}`, columnsThatAllowHtml: TabObject.getColumnsThatAllowHtml(), columnsTranslations: columnsTranslations, translateFieldFunction: instrFieldTranslator.getTranslatedValue })) : (React.createElement("div", { className: "price-feed-cs__table-container contract-specs-tab__no-results-container" },
                IconNoResults,
                React.createElement("span", null, "No results found"))),
            numOfPages > 1 && React.createElement("div", { className: "jobBoard__pagination" },
                React.createElement(JobBoardPagination, { currentPage: currentPage, numOfPages: numOfPages, setCurrentPage: setCurrentPage, isSmallScreen: false }))),
        additionalInfo && React.createElement(AdditionalInformation, { ...additionalInfo })));
};
