import * as React from "react";
import { Button } from "../Button";
import { Container, ContainerFW } from "../global";
import { LayoutOptions } from "./LayoutOptions/LayoutOptions";
export const FiltersRibbon = ({ subscriptionText, searchText, subscriptionButtonText, filterText, type, viewToggle, view, filterToggle, filters, hasLayoutOptions = false, searchQuery, setSearchQuery, hasFiltersApplied, }) => {
    const [data, setData] = React.useState("");
    const [searchVal, setSearchVal] = React.useState(searchQuery);
    React.useEffect(() => {
        setSearchVal(searchQuery);
    }, [searchQuery]);
    const fieldHandler = React.useCallback(e => setData(e.target.value), [data]);
    const viewHandler = React.useCallback((v) => viewToggle(v), [viewToggle]);
    const searchFieldHandler = React.useCallback(e => {
        setData(e.target.value);
        setSearchVal(e.target.value);
    }, [data]);
    const searchClickHandler = React.useCallback((e) => {
        e.key === "Enter" && setSearchQuery(data);
    }, [data]);
    const subscribeHandler = React.useCallback((e) => {
        e.preventDefault();
        console.warn(data);
    }, [data]);
    const toggleFilters = React.useCallback(() => {
        filterToggle(!filters);
    }, [filters]);
    const ribbonWidgetTypes = {
        search: (React.createElement("div", { className: "subscribeRibbon__widget" },
            React.createElement("input", { onKeyDown: searchClickHandler, onChange: searchFieldHandler, value: searchVal, className: "search-banner__input subscribeRibbon__input subscribeRibbon__input--search", type: "search", placeholder: searchText, maxLength: 100 }))),
        subscribe: (React.createElement("div", { className: "subscribeRibbon__widget" },
            React.createElement("input", { onChange: fieldHandler, value: data, className: "search-banner__input subscribeRibbon__input", type: "email", placeholder: subscriptionText }),
            subscriptionButtonText && React.createElement(Button, { label: subscriptionButtonText ? subscriptionButtonText : "", primary: true, onClick: subscribeHandler })))
    };
    const ribbonWidget = type ? ribbonWidgetTypes[type] : undefined;
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "subscribeRibbon" },
            React.createElement(Container, null,
                React.createElement("div", { className: "subscribeRibbon__container" },
                    ribbonWidget,
                    React.createElement("div", { className: "subscribeRibbon__actions" },
                        hasLayoutOptions && React.createElement(LayoutOptions, { viewHandler: viewHandler, view: view }),
                        React.createElement("div", { onClick: toggleFilters, className: "subscribeRibbon__toggle-filters" },
                            React.createElement("p", { className: `${hasFiltersApplied && "active"}` },
                                filterText,
                                " ",
                                chevronUp()))))))));
};
const chevronUp = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6.708 14.708a.996.996 0 0 0 1.41 0l3.88-3.88 3.88 3.88a.998.998 0 0 0 1.41-1.41l-4.59-4.59a.996.996 0 0 0-1.41 0l-4.59 4.59c-.38.38-.38 1.02.01 1.41z", fill: "#fff" })));
