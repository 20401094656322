import React from "react";
import { usePageViewStyle } from "../../../hooks/usePageViewStyle";
import { Gap } from "../../Gaps/Gap";
import { ContainerFW, Container } from "../../global";
import { NewsCard } from "./NewsCard/NewsCard";
import { JobBoardPagination } from "../../JobBoard/JobBoardPagination/JobBoardPagination";
import { Heading } from "../../Heading";
import { EconomicCalendarMemoized } from "../../EconomicCalendar";
import { TwitterFeedEmbed } from "./TwitterFeedEmbed";
export const NewsCardDeck = ({ children, title, currentPage, currentPageItems, numOfPages, setCurrentPage, filtersRibbonElement, filtersElement, noWidgets, twitterFeed, }) => {
    const { viewStyle: view } = usePageViewStyle();
    const hasResultsOnPage = currentPageItems.length > 0;
    const hasTwitterEmbed = !noWidgets && twitterFeed?.url;
    return (React.createElement(ContainerFW, null,
        React.createElement("section", { className: "newsCardDeck" },
            React.createElement(Container, null,
                React.createElement("div", { className: "newsCardDeck__inner-wrapper" },
                    title && React.createElement(Heading, { design: "h3-bold-small" }, title),
                    React.createElement("div", { className: "newsCardDeck__articles" },
                        React.createElement("div", { className: "newsCardDeck__controls" },
                            filtersRibbonElement,
                            filtersElement),
                        children,
                        React.createElement(Gap, { mobileGap: "24px", desktopGap: "32px", gapColor: "#fff" }),
                        hasResultsOnPage &&
                            (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "newsCardDeck__list" },
                                    currentPageItems.map((card, i) => (React.createElement(NewsCard, { key: i, ...card }))),
                                    " "),
                                React.createElement("div", { className: "jobBoard__pagination" },
                                    React.createElement(JobBoardPagination, { currentPage: currentPage, numOfPages: numOfPages, setCurrentPage: setCurrentPage, isSmallScreen: view !== "large" }))))),
                    React.createElement("div", { className: "newsCardDeck__social-feed" }, hasTwitterEmbed ? React.createElement(TwitterFeedEmbed, { twitterFeedUrl: twitterFeed.url, twitterFeedPlaceholderText: twitterFeed.placeholderText })
                        : React.createElement(EconomicCalendarMemoized, null)))))));
};
