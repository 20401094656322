import React from "react";
import { Heading } from "../../Heading";
import { ContainerFW, Container } from "../../global";
import { getColors } from "../../../utils";
export const SimpleTitleAndSubtitleBanner = ({ subTitle, title, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "simple-title-subtitle-banner__root" },
                React.createElement(Heading, { design: "h1-regular" }, title),
                React.createElement("p", { className: "h5-semi-bold" }, subTitle)))));
};
