import React from "react";
import { Heading } from "../../Heading";
import { Container, ContainerFW } from "../../global";
import { Swiper, SwiperSlide } from "swiper/react";
import { getColors } from "../../../utils/getColors";
import { SingleCard } from "../CardsDeckWithTitleAndSubSwiper/SingleCard/SingleCard";
export const CardDeckGradientSwiper = ({ title, cards, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "card-deck-gradient-swiper__root" },
                React.createElement(Heading, { design: "h3-bold" }, title),
                React.createElement("div", { className: "card-deck-gradient-swiper__root__swiper-view" },
                    React.createElement(Swiper, { pagination: {
                            clickable: true,
                            renderBullet: () => ("<span class='card-deck-gradient-swiper__bullet swiper-pagination-bullet'></span>"),
                        }, breakpoints: {
                            375: {
                                slidesPerView: 1.05,
                                spaceBetween: 7
                            },
                            601: {
                                slidesPerView: 2,
                                spaceBetween: 12
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 24
                            },
                        } }, cards.map((card, i) => React.createElement(SwiperSlide, { key: `cards-deck-with-title-sub-swipe-slide-${i}` },
                        React.createElement(SingleCard, { ...card })))))))));
};
