import * as React from "react";
import { Breadcrumbs } from "../../../Components/Breadcrumbs";
import { IconFb, IconIn, IconTw } from "../../../Components/svgIcons";
export const PressBanner = (props) => (React.createElement("div", { className: "pressBanner__container" },
    props.breadcrumbs && React.createElement(Breadcrumbs, { breadcrumbs: props.breadcrumbs }),
    React.createElement("div", { className: "pressBanner" },
        React.createElement("h1", { className: "h1-regular-small" }, props.title),
        React.createElement("div", { className: "pressBanner__info" },
            props.isAnnouncement ?
                React.createElement("div", { className: "pressBanner__data" },
                    React.createElement("span", { className: "pressBanner__data--date" },
                        updatedDate(props.date),
                        " ",
                        updatedHours(props.date)))
                :
                    React.createElement("div", { className: "pressBanner__data" },
                        React.createElement("span", { className: "pressBanner__data--cfd" }, props.cfdCategory),
                        React.createElement("span", { className: "pressBanner__data--category" }, props.category),
                        React.createElement("span", { className: "pressBanner__data--date" }, updatedDate(props.date)),
                        props.authorUrl ? React.createElement("a", { href: props.authorUrl, className: "pressBanner__data--author", target: "_blank", rel: "noopener noreferrer" }, props.authorName) : React.createElement("span", { className: "pressBanner__data--author" }, props.authorName)),
            React.createElement("div", { className: "pressBanner__share" },
                React.createElement("span", null, props.shareText),
                React.createElement("a", { target: "_blank", className: "pressBanner__share--fb", href: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, rel: "noreferrer" }, IconFb),
                React.createElement("a", { target: "_blank", className: "pressBanner__share--tw", href: `https://twitter.com/intent/tweet?text=${props.title}&url=${window.location.href}`, rel: "noreferrer" }, IconTw),
                React.createElement("a", { target: "_blank", className: "pressBanner__share--in", href: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${props.title}`, rel: "noreferrer" }, IconIn))))));
export const updatedDate = function (d) {
    const dt = new Date(d);
    const locale = document.documentElement.lang;
    const month = dt.toLocaleString(locale, { month: "long" });
    if (locale === "ar-AE") {
        return ` ${dt.getDate()} ${month} ${dt.getFullYear()}`;
    }
    return `${month.substring(0, 3)} ${dt.getDate()}, ${dt.getFullYear()}`;
};
export const updatedHours = function (d) {
    const dt = new Date(d);
    const formatter = new Intl.DateTimeFormat(document.documentElement.lang, { hour: "2-digit", minute: "2-digit", second: "2-digit" });
    const formatted = formatter.format(dt).substring(0, 5);
    return `${formatted}`;
};
