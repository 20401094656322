import React from "react";
export const TwitterFeedEmbed = ({ twitterFeedUrl, twitterFeedPlaceholderText }) => {
    React.useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return React.createElement("div", { className: "widget-container" },
        React.createElement("a", { className: "twitter-timeline", "data-tweet-limit": "3", "data-chrome": "nofooter transparent noborders", "data-link-color": "#7eaf3e", href: twitterFeedUrl, target: "_blank", rel: "noreferrer" }, twitterFeedPlaceholderText));
};
