import React from "react";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { handleLinkTarget } from "../../utils";
const COLOR_SCHEME_BTN_CLASSES = {
    green: "tmx25-square-green-green",
    lightpurple: "tmx25-square-lightpurple"
};
export const RibbonWithOutStatLine = ({ buttonHref, buttonLabel, opensInNewTab, linkHref, linkLabel, linkOpensInNewTab, subTitle, title, backgroundColor, ribbonColorScheme }) => {
    const colorSchemeName = ribbonColorScheme || "green";
    const linkHrefTarget = handleLinkTarget(linkOpensInNewTab);
    return (React.createElement(ContainerFW, { backgroundColor: backgroundColor || "inherit" },
        React.createElement("div", { className: `ribbon-without-stat-line-root ribbon-without-stat-line-root--scheme-${colorSchemeName}` },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement("div", { className: "ribbon-without-stat-line-root__contentWrapper" },
                    React.createElement("div", { className: "ribbon-without-stat-line-root__contentWrapper__textWrapper" },
                        React.createElement(Heading, { design: "h4-semi-bold" },
                            React.createElement("span", { dangerouslySetInnerHTML: {
                                    __html: title,
                                } })),
                        React.createElement("div", { className: "ribbon-without-stat-line-root__contentWrapper__subtitle", dangerouslySetInnerHTML: {
                                __html: subTitle,
                            } })),
                    React.createElement("div", { className: "ribbon-without-stat-line-root__contentWrapper__buttonsWrapper" },
                        React.createElement(ButtonV2, { href: buttonHref, design: COLOR_SCHEME_BTN_CLASSES[colorSchemeName], openInNewTab: opensInNewTab }, buttonLabel),
                        linkLabel && React.createElement("a", { className: "ribbon-without-stat-line-root__contentWrapper__link", href: linkHref, target: linkHrefTarget }, linkLabel)))))));
};
