import React from "react";
import { ImgV2 } from "../../ImgV2";
import { Container, ContainerFW } from "../../global";
import { Heading } from "../../Heading";
import { ButtonV2 } from "../../ButtonV2";
export const InterchangeableImageCardDeck = ({ title, subTitle, imageUrl, buttonText, buttonUrl, imagePosition, openInNewTab }) => (React.createElement(ContainerFW, null,
    React.createElement(Container, { className: "g-container--tablet-margins" },
        React.createElement("div", { className: `interchangeable-image-card-deck__root ${imagePosition === "right" && "rightAligned"}  ` },
            React.createElement("div", { className: "image__wrapper" },
                React.createElement(ImgV2, { src: imageUrl })),
            React.createElement("div", { className: "texts__wrapper" },
                React.createElement(Heading, { design: "h2-regular" }, title),
                React.createElement("p", { className: "p-regular" }, subTitle),
                (buttonText && buttonUrl) && React.createElement(ButtonV2, { openInNewTab: openInNewTab, design: "secondary-large-dbg", href: buttonUrl }, buttonText))))));
