import React, { useEffect, useState, useCallback, useRef } from "react";
import { ContainerFW, Container } from "../global";
import { InstrumentSlider } from "../InstrumentSlider";
import pako from "pako";
import { Img } from "../Img";
const Forex = ["EURUSD", "USDCHF", "NZDUSD", "EURCZK", "EURGBP", "AUDJPY", "USDZAR", "USDJPY", "GBPJPY", "GBPUSD", "AUDUSD"];
const Indices = ["GER40", "ESTX50", "US30", "FRA40", "CHINA50", "AUS200", "USDINDEX", "SPX500", "HK50", "NAS100"];
const Commodities = ["XAUUSD", "XAGUSD", "BRENT", "WTI", "NGAS", "COPPER", "XAUEUR", "XPTUSD"];
const ETFs = ["VOO", "GLD", "NEWPLAT", "VHY", "A200", "EEM"];
const Futures = ["SOYBEANS", "CORN"];
const Stocks = ["VISA", "DISNEY", "PFIZER", "GM", "UBER", "GILEAD", "WEIBO", "CITIGROUP", "NIKE", "LOCKHEED", "JPMORGAN", "SALESFORCE", "COINBASE", "AMD", "BOEING", "NIO", "MCDONALDS", "GAMESTOP", "BERKSHIRE", "INTEL", "REDDIT", "AMAZON", "CARVANA", "ALPHABET", "ALIBABA", "APPLE", "TESLA", "MICROSOFT", "META", "NETFLIX", "NVIDIA", "GME", "CRWD", "ZM", "LULU", "GS", "SNAP", "PYPL", "MSTR", "RIOT", "SBUX", "NVO", "AMC", "MRNA", "ABNB", "NET", "RIVN", "SHOP", "SPOT", "BIDU"];
const Crypto = ["BTCUSD", "ETHUSD", "ADAUSD", "UNIUSD", "SOLUSD", "DASHUSD", "ALGOUSD", "DOGEUSD"];
const Popular = ["TESLA", "US30", "BTCUSD", "GME"];
export const instrumentsMap = {
    "": [],
    forex: Forex,
    indices: Indices,
    commodities: Commodities,
    etfs: ETFs,
    futures: Futures,
    stocks: Stocks,
    crypto: Crypto,
    popular: Popular,
};
export const PriceFeed = ({ title, tableInfo, instrumentIcons, marketsInfo, resources, footerText, session, wsUrl, activeMarket, }) => {
    const [activeRowIndex, setActiveRowIndex] = useState(-1);
    const [instruments, setInstruments] = useState([]);
    const [filteredInstruments, setFilteredInstruments] = useState([]);
    const [filteredInstrumentIcons, setFilteredInstrumentIcons] = useState([]);
    const [currentMarketTab, setCurrentMarketTab] = useState(activeMarket);
    const prevAskPrices = useRef(new Map());
    const prevBidPrices = useRef(new Map());
    useEffect(() => {
        const websocket = new WebSocket(`${wsUrl}/price-feed/${session}`);
        websocket.binaryType = "arraybuffer";
        const pingRequest = new TextEncoder().encode("ping");
        let interval;
        websocket.onopen = () => {
            interval = setInterval(() => {
                websocket.send(pingRequest);
            }, 1000);
        };
        websocket.onmessage = (event) => {
            const data = event.data;
            const message = pako.inflate(data, { to: "string" });
            updatePriceFeed(message);
        };
        return () => {
            clearInterval(interval);
            websocket.close();
        };
    }, [currentMarketTab]);
    useEffect(() => {
        setFilteredInstruments(instruments.filter((item) => item.groupCode === currentMarketTab));
        setFilteredInstrumentIcons(instrumentIcons.filter((item) => item.market === currentMarketTab));
    }, [currentMarketTab]);
    const updatePriceFeed = (message) => {
        const dict = JSON.parse(message);
        const instruments = [];
        for (const key in dict) {
            if (key.includes("quote")) {
                const group = key.split("_")[0];
                const parsedValues = JSON.parse(dict[key]);
                if (instrumentsMap[group.toLowerCase()].includes(parsedValues.Code)) {
                    const dailyChange = calcDailyChange(group, parsedValues.Code, parsedValues.Bid, parsedValues.Ask, dict);
                    const prevBid = prevBidPrices.current.get(parsedValues.Code) ?? 0;
                    const prevAsk = prevAskPrices.current.get(parsedValues.Code) ?? 0;
                    const instrument = {
                        name: parsedValues.Name,
                        code: parsedValues.Code,
                        groupCode: group,
                        bid: parsedValues.Bid,
                        bidStyle: bidAskStyle(parsedValues.Bid, prevBid),
                        bidIcon: bidAskIcon(parsedValues.Bid, prevBid),
                        ask: parsedValues.Ask,
                        askStyle: bidAskStyle(parsedValues.Ask, prevAsk),
                        askIcon: bidAskIcon(parsedValues.Ask, prevAsk),
                        spread: calcSpread(group, parsedValues.Code, parsedValues.Bid, parsedValues.Ask, dict),
                        dailyChange,
                        dailyChangeStyle: dailyChangeStyle(+dailyChange),
                        dailyChangeIcon: dailyChangeIcon(+dailyChange),
                    };
                    prevAskPrices.current.set(parsedValues.Code, parsedValues.Ask);
                    prevBidPrices.current.set(parsedValues.Code, parsedValues.Bid);
                    instruments.push(instrument);
                }
            }
        }
        setInstruments(instruments);
        setFilteredInstruments(instruments.filter((item) => item.groupCode === currentMarketTab));
    };
    const calcSpread = (group, code, bid, ask, dict) => {
        const marketItem = JSON.parse(dict[`${group}_marketItem_${code}`]);
        let decimalPrecision = 0;
        let tickSize = 0;
        if (dict[`${group}_marketItemInfo_${code}`]) {
            const marketItemInfo = JSON.parse(dict[`${group}_marketItemInfo_${code}`]);
            decimalPrecision =
                marketItemInfo.DecimalPrecision ?? marketItem.DecimalPrecision;
            tickSize = marketItemInfo.TickSize ?? marketItem.TickSize;
        }
        else {
            decimalPrecision = marketItem.DecimalPrecision;
            tickSize = marketItem.TickSize;
        }
        let decimal = tickSize >= 1.0 ? 0 : decimalPrecision;
        decimal = decimal > 2 ? decimal - 1 : decimal;
        const spread = ((ask - bid) * Math.pow(10, decimal)).toFixed(1);
        return spread;
    };
    const calcDailyChange = (group, code, bid, ask, dict) => {
        if (dict[`${group}_prevDayClosePrice_${code}`]) {
            const parsedValues = JSON.parse(dict[`${group}_prevDayClosePrice_${code}`]);
            const prevClose = parsedValues.PrevClose ?? 0;
            const averageCurrentPrice = (ask + bid) / 2;
            const dailyValue = averageCurrentPrice - prevClose;
            const dailyPercent = ((dailyValue / prevClose) * 100).toFixed(2);
            return `${dailyPercent}%`;
        }
        return "N/A";
    };
    const bidAskStyle = (currentPrice, previousPrice) => {
        if (currentPrice >= previousPrice) {
            return "price-feed__positive";
        }
        else {
            return "price-feed__negative";
        }
    };
    const dailyChangeStyle = (value) => {
        if (value >= 0) {
            return "price-feed__positive";
        }
        else {
            return "price-feed__negative";
        }
    };
    const bidAskIcon = (currentPrice, previousPrice) => {
        if (currentPrice >= previousPrice) {
            return resources[0];
        }
        else {
            return resources[1];
        }
    };
    const dailyChangeIcon = (value) => {
        if (value >= 0) {
            return resources[0];
        }
        else {
            return resources[1];
        }
    };
    const handleRowClick = useCallback((event) => {
        event.preventDefault();
        const index = parseInt(event.currentTarget.getAttribute("data-id") ?? "0");
        setActiveRowIndex(index);
    }, []);
    const handleMarketClick = useCallback((market) => {
        setCurrentMarketTab(market);
    }, []);
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "price-feed" },
            React.createElement(Container, null,
                React.createElement(InstrumentSlider, { title: title, items: marketsInfo, currentMarketTab: activeMarket, onMarketClick: handleMarketClick }),
                React.createElement("div", { className: "price-feed__table" },
                    React.createElement("div", { className: "price-feed__header" }, tableInfo.map((item, i) => (React.createElement("div", { key: `price-feed__headerCell-${i}`, className: "price-feed__headerCell" }, item.title.toUpperCase())))),
                    filteredInstruments.map((item, i) => (React.createElement("div", { key: `price-feed__row-${i}`, className: `price-feed__row ${activeRowIndex === i &&
                            "price-feed__highlighted"}`, "data-id": i, onClick: handleRowClick },
                        React.createElement("div", { className: "price-feed__cellContainer" },
                            React.createElement(Img, { className: "div price-feed__instrumentIcon", src: filteredInstrumentIcons.filter((el) => el.title === item.code)[0]?.imageUrl ?? "", alt: filteredInstrumentIcons.filter((el) => el.title === item.code)[0]?.imageAlt ?? "" }),
                            React.createElement("div", { className: "price-feed__cellTitle" }, item.code)),
                        React.createElement("div", { className: "price-feed__cellContainer" },
                            React.createElement(Img, { className: "price-feed__upDownIcon", src: item.bidIcon }),
                            React.createElement("div", { className: `price-feed__cellTitle ${item.bidStyle}` }, item.bid)),
                        React.createElement("div", { className: "price-feed__cellContainer" },
                            React.createElement(Img, { className: "price-feed__upDownIcon", src: item.askIcon }),
                            React.createElement("div", { className: `price-feed__cellTitle ${item.askStyle}` }, item.ask)),
                        React.createElement("div", { className: "price-feed__cellContainer" },
                            React.createElement("div", { className: "price-feed__cellTitle" }, item.spread)),
                        React.createElement("div", { className: "price-feed__cellContainer" },
                            React.createElement(Img, { className: "price-feed__upDownIcon", src: item.dailyChangeIcon }),
                            React.createElement("div", { className: `price-feed__cellTitle ${item.dailyChangeStyle}` }, item.dailyChange)))))),
                React.createElement("div", { className: "price-feed__footerText" }, footerText)))));
};
