import React from "react";
import { getColors } from "../../utils";
import { Container, ContainerFW } from "../global";
import { TwoSectionWithTitleNewCard } from "./TwoSectionWithTitleNewCard";
export const TwoSectionWithTitleNew = ({ mainTitle, mainSubTitle, leftCardContent, rightCardContent, bgColor }) => {
    const { bgColor: backgroundColor } = getColors(bgColor);
    return (React.createElement(ContainerFW, { backgroundColor: backgroundColor },
        React.createElement("div", { className: "two-section-with-title-new" },
            React.createElement(Container, null,
                React.createElement("div", { className: "two-section-with-title-new__container" },
                    React.createElement("div", { className: "two-section-with-title-new__title-wrapper" },
                        React.createElement("h2", null, mainTitle),
                        React.createElement("h5", null, mainSubTitle)),
                    React.createElement("div", { className: "two-section-with-title-new__cards-wrapper" },
                        React.createElement(TwoSectionWithTitleNewCard, { title: leftCardContent.title, items: leftCardContent.items, mainIcon: leftCardContent.mainIcon }),
                        React.createElement(TwoSectionWithTitleNewCard, { title: rightCardContent.title, items: rightCardContent.items, mainIcon: rightCardContent.mainIcon })))))));
};
