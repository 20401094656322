import * as React from "react";
import { useInView } from "react-intersection-observer";
import { pageViewStyle } from "../../pageViewStyle";
import { PressBanner, updatedDate } from "../Banners";
import { Breadcrumbs } from "../Breadcrumbs";
import { ButtonV2 } from "../ButtonV2";
import { ContainerFW, Container } from "../global";
import { Img } from "../Img";
import { IconArrowLeft, IconCalendar } from "../svgIcons";
export const PressArticle = (props) => {
    const [view, setView] = React.useState(pageViewStyle.getView());
    const { ref, inView } = useInView({
        threshold: 1,
        rootMargin: "500px 0px 0px 0px"
    });
    React.useEffect(() => {
        !view && setView(pageViewStyle.getView());
    }, [view]);
    const relatedArticles = React.useMemo(() => props.sidebar?.items?.filter((a) => a.articleTitle !== props.pressBanner.title), [props.pressBanner.title, props.sidebar?.items]);
    const sidebarArticles = () => (React.createElement(React.Fragment, null, relatedArticles?.map((item, i) => (React.createElement("a", { href: item.articleURL, className: "pressArticle__sidebar-item", key: `sidebar-article-${i}` },
        React.createElement("div", { className: "pressBanner__data" },
            React.createElement("span", { className: "pressBanner__data--cfd" }, item.articleCfdTrading),
            React.createElement("span", { className: "pressBanner__data--category" }, item.articleCategory),
            React.createElement("span", { className: "pressBanner__data--date" }, updatedDate(item.articleDate))),
        React.createElement("h5", null, item.articleTitle))))));
    const fixedClass = () => (inView && view === "large") ? "pressArticle__sidebar--fixed" : "";
    const getSidebarIcon = (sidebar) => sidebar.headerIcon ? React.createElement(Img, { src: sidebar.headerIcon, alt: sidebar.headerTitle }) : IconCalendar;
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement("div", { className: "pressArticle" },
            React.createElement(Container, null,
                React.createElement(Breadcrumbs, { ...props.breadcrumbs }),
                React.createElement("div", { className: "pressArticle__inner" },
                    React.createElement(PressBanner, { ...props.pressBanner }),
                    props.imageUrl && (React.createElement(Img, { className: "pressArticle__teaserImg", src: props.imageUrl, alt: props.imageAlt })),
                    React.createElement("div", { className: "pressArticle__content", dangerouslySetInnerHTML: { __html: props.content } }),
                    props.disclaimerText && React.createElement("section", { className: "pressArticle__disclaimerText", dangerouslySetInnerHTML: {
                            __html: props.disclaimerText
                        } }),
                    React.createElement("div", { className: "button-v2-wrapper flex" },
                        React.createElement("a", { ref: ref, href: props.bottomLinkUrl, className: "button-v2 flex ghost-medium-wbg" },
                            IconArrowLeft,
                            props.bottomLinkText))),
                props.sidebar &&
                    React.createElement("aside", { className: `pressArticle__sidebar ${fixedClass()}` },
                        props.sidebar.headerLink && React.createElement("a", { href: props.sidebar.headerLink, className: "pressArticle__sidebar-header" },
                            React.createElement("div", null,
                                React.createElement("span", { className: "text-weight-bold" }, props.sidebar.headerTitle),
                                React.createElement("span", { className: "caption2-regular" }, props.sidebar.headerDescription)),
                            getSidebarIcon(props.sidebar)),
                        props.sidebar.items && React.createElement("div", { className: "pressArticle__sidebar-main" },
                            React.createElement("div", { className: "caption-bold" }, props.sidebar.itemsTitle),
                            sidebarArticles()),
                        React.createElement("div", { className: "pressArticle__sidebar-footer" },
                            React.createElement("div", { className: "text-weight-bold" }, props.sidebar.footerTitle),
                            React.createElement(ButtonV2, { design: "cta-medium-wbg", href: props.sidebar.footerButtonUrl }, props.sidebar.footerButtonText)))))));
};
