import React from "react";
import { getColors } from "../../utils";
import { ContactOptions } from "../ContactOptions";
import { Container, ContainerFW } from "../global";
import { ContactForm } from "./ContactForm";
export const ContactUsWrapper = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "contact-us-wrapper_root" },
                React.createElement(ContactOptions, { contactOptions: props.contactOptions }),
                React.createElement(ContactForm, { ...props, backgroundColor: "#414D5D" })))));
};
