import React from "react";
import { getColors } from "../../utils";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { Aspects } from "./Aspects/Aspects";
export const SectionWithFeaturedLists = ({ mainTitle, subTitle, criteria, orLabel, backGroundColor, firstButtonLabel, firstButtonUrl, firstButtonOpenOnNewPage, secondButtonLabel, secondButtonUrl, secondButtonOpenOnNewPage, centeredColStyle, withThreeButtonStyle, thirdButtonLabel, thirdButtonUrl }) => {
    const { bgColor } = getColors(backGroundColor);
    const buttonWrapperComponent = () => (React.createElement("div", { className: "section-with-featured-lists-root__buttonWrapper" },
        React.createElement(ButtonV2, { href: firstButtonUrl, openInNewTab: firstButtonOpenOnNewPage, design: "cta-large-dbg" }, firstButtonLabel),
        (secondButtonLabel && secondButtonUrl) && React.createElement(ButtonV2, { href: secondButtonUrl, openInNewTab: firstButtonOpenOnNewPage, design: "cta-large-dbg" }, secondButtonLabel),
        (orLabel && !withThreeButtonStyle) && React.createElement("p", null, orLabel),
        React.createElement(ButtonV2, { href: thirdButtonUrl, openInNewTab: firstButtonOpenOnNewPage, design: "ghost-large-wbg" }, thirdButtonLabel)));
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "section-with-featured-lists-root" },
            React.createElement(Container, { className: `g-container--tablet-margins ${centeredColStyle && "centeredColumn"} ${withThreeButtonStyle && "withThreeButton"}` },
                React.createElement("div", { className: "section-with-featured-lists-root__titleWrapper" },
                    React.createElement(Heading, { design: "h2-regular" }, mainTitle),
                    React.createElement("p", { className: "p-regular" }, subTitle)),
                React.createElement("div", { className: "section-with-featured-lists-root__criteriaWrapper" }, criteria && criteria?.length > 0 && criteria?.map((a, i) => (React.createElement("div", { key: `unique${a.aspects}`, className: "section-with-featured-lists-root__criteriaWrapper__eligibilityTests" },
                    React.createElement(Heading, { design: "h3-semi-bold-small" }, a.title),
                    a.aspects.length > 0 && a.aspects.map((aspectItems, index) => (React.createElement("div", { key: `unique${index}`, className: "section-with-featured-lists-root__criteriaWrapper__eligibilityTests__root-aspectWrapper" },
                        React.createElement("div", { className: "section-with-featured-lists-root__criteriaWrapper__eligibilityTests__root-aspectWrapper__aspectWrapper" },
                            React.createElement("p", { className: "section-with-featured-lists-root__criteriaWrapper__eligibilityTests__root-aspectWrapper__aspectWrapper__aspectTitle text-nav" }, index + 1),
                            React.createElement(Aspects, { items: aspectItems.items })),
                        (index < a.aspects.length - 1 && orLabel) && React.createElement("p", { className: "caption-bold orLabel" }, orLabel.toUpperCase())))))))),
                buttonWrapperComponent()))));
};
