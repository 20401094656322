import React from "react";
import { ContainerFW, Container } from "../global";
import { ImgV2 } from "../ImgV2";
import { Heading } from "../Heading";
import { getColors } from "../../utils/getColors";
export const ImageAndText = ({ title, description, imageURL, imageAlt, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "image-and-text" },
                React.createElement("div", { className: "image-and-text__text-wrapper" },
                    React.createElement(Heading, { design: "h2-regular" }, title),
                    React.createElement("p", { className: "image-and-text__description", dangerouslySetInnerHTML: { __html: description } })),
                React.createElement(ImgV2, { src: imageURL, alt: imageAlt })))));
};
