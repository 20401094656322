import React, { useCallback, useState } from "react";
import { Container, ContainerFW } from "../global";
import { getColors } from "../../utils/getColors";
import { ImgV2 } from "./../ImgV2/ImgV2";
export const LoginTabs = ({ tabs, backgroundColor, ttLogoIcon, mt4LogoIcon, mt5LogoIcon, tpLogoIcon, ttButtonLabel, mt4ButtonLabel, mt5ButtonLabel, tpButtonLabel, desktopTitle, mobileTitle }) => {
    const { bgColor } = getColors(backgroundColor);
    const [openTTTabs, setOpenTTTabs] = useState(true);
    const [openMT4Tabs, setOpenMT4Tabs] = useState(false);
    const [openMT5Tabs, setOpenMT5Tabs] = useState(false);
    const [openTPTabs, setOpenTPTabs] = useState(false);
    const handleTTButtonClick = useCallback(() => {
        setOpenTTTabs(true);
        setOpenMT4Tabs(false);
        setOpenMT5Tabs(false);
        setOpenTPTabs(false);
    }, []);
    const handleMT4ButtonClick = useCallback(() => {
        setOpenMT4Tabs(true);
        setOpenTTTabs(false);
        setOpenMT5Tabs(false);
        setOpenTPTabs(false);
    }, []);
    const handleMT5ButtonClick = useCallback(() => {
        setOpenMT5Tabs(true);
        setOpenTTTabs(false);
        setOpenMT4Tabs(false);
        setOpenTPTabs(false);
    }, []);
    const handleTPButtonClick = useCallback(() => {
        setOpenTPTabs(true);
        setOpenTTTabs(false);
        setOpenMT4Tabs(false);
        setOpenMT5Tabs(false);
    }, []);
    const tabsBox = (desktopTitle, mobileTitle, tabLabel) => (React.createElement("div", { className: "login-tabs-container__tabs-wrapper" },
        React.createElement("div", { className: "login-tabs-container__desktop-tabs" },
            React.createElement("p", { className: "login-tabs-container__desktop-tabs--title" }, desktopTitle),
            React.createElement("ul", { className: "login-tabs-container__desktop-tabs--list" }, tabs.map((item, index) => ((item.device === "desktop" && item.tabPosition === tabLabel) &&
                React.createElement("li", { key: index, className: "login-tabs-container__desktop-tabs--item" },
                    React.createElement(ImgV2, { src: item.iconUrl, alt: item.title, loading: "eager" }),
                    React.createElement("a", { href: item.linkUrl, target: "_blank", dangerouslySetInnerHTML: {
                            __html: item.title,
                        }, rel: "noreferrer" })))))),
        React.createElement("div", { className: "login-tabs-container__mobile-tabs" },
            React.createElement("p", { className: "login-tabs-container__mobile-tabs--title" }, mobileTitle),
            React.createElement("ul", { className: "login-tabs-container__mobile-tabs--list" }, tabs.map((item, index) => ((item.device === "mobile" && item.tabPosition === tabLabel) &&
                React.createElement("li", { key: index, className: "login-tabs-container__mobile-tabs--item" },
                    React.createElement(ImgV2, { src: item.iconUrl, alt: item.title, loading: "eager" }),
                    React.createElement("a", { href: item.linkUrl, target: "_blank", dangerouslySetInnerHTML: {
                            __html: item.title,
                        }, rel: "noreferrer" }))))))));
    const buttonBox = (openTab, buttonLabel, buttonHandle, logoIcon, tabLabel) => (buttonLabel && React.createElement("div", { className: "login-tabs-container__button-wrapper" },
        React.createElement("button", { onClick: buttonHandle, className: `login-tabs-container__button-box ${openTab ? "active-button" : ""}` },
            React.createElement("img", { src: logoIcon }),
            buttonLabel,
            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "17", height: "18", viewBox: "0 0 17 18", fill: "none" },
                React.createElement("path", { d: "M1 9.00004L16 9.00004M16 9.00004L8.91667 16.0834M16 9.00004L8.91667 1.9167", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))),
        openTab && tabsBox(desktopTitle, mobileTitle, tabLabel)));
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "login-tabs-container" },
                buttonBox(openTTTabs, ttButtonLabel, handleTTButtonClick, ttLogoIcon, "TT"),
                buttonBox(openMT4Tabs, mt4ButtonLabel, handleMT4ButtonClick, mt4LogoIcon, "MT4"),
                buttonBox(openMT5Tabs, mt5ButtonLabel, handleMT5ButtonClick, mt5LogoIcon, "MT5"),
                buttonBox(openTPTabs, tpButtonLabel, handleTPButtonClick, tpLogoIcon, "TP")))));
};
