import * as React from "react";
import ".";
import { Button } from "../Button";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { ImgV2 } from "../ImgV2";
import { IconCloseWhite } from "../svgIcons/iconCloseWhite";
import { IconPlayButton } from "../svgIcons/playButton";
export const HeroBannerWithVideo = ({ title, text, text2, imageUrl, imageAlt, linkText, linkUrl, videoText, videoText2, videoUrl, imageTopUrl, imageTopAlt, buttonOpenInNewTab = false }) => {
    const [isPopoverOpen, setPopoverOpen] = React.useState(false);
    const openInNewTabValue = buttonOpenInNewTab !== true ? false : true;
    const handlePopoverToggle = React.useCallback((e) => {
        e.preventDefault();
        setPopoverOpen(!isPopoverOpen);
    }, [isPopoverOpen]);
    const handlePopoverClose = React.useCallback((e) => {
        e.preventDefault();
        setPopoverOpen(false);
    }, []);
    const FullscreenPopover = ({ isOpen, onClose, children }) => (React.createElement("div", { className: `fullscreen-popover ${isOpen ? "open" : ""}` },
        React.createElement("div", { className: "fullscreen-popover__content" },
            React.createElement("span", { onClick: onClose, className: "fullscreen-popover__close" }, IconCloseWhite),
            children)));
    const renderVideoLinks = React.useCallback(() => (React.createElement(React.Fragment, null,
        videoText && (React.createElement("a", { onClick: handlePopoverToggle, href: "#", className: "HeroBannerWithVideo__link color-dark-default" },
            IconPlayButton,
            React.createElement("span", null, videoText))),
        videoText2 && (React.createElement("a", { onClick: handlePopoverToggle, href: "#", className: "HeroBannerWithVideo__link2 color-dark-default p-bold" },
            IconPlayButton,
            React.createElement("span", null, videoText2))))), [videoText, videoText2, handlePopoverToggle]);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: "HeroBannerWithVideo" },
                imageTopUrl && React.createElement(ImgV2, { className: "HeroBannerWithVideo__img-top img-top", src: imageTopUrl, alt: imageTopAlt }),
                React.createElement("h1", { className: "color-dark-default" }, title),
                text && React.createElement("h5", { className: "HeroBannerWithVideo__description color-dark-default h5-semi-bold" }, text),
                text2 && React.createElement("h5", { className: "HeroBannerWithVideo__description color-dark-80 h5-semi-bold" }, text2),
                React.createElement(Button, { label: linkText, href: linkUrl, openinnewtab: openInNewTabValue.toString(), style: { color: "#0E1D31" }, primary: true }),
                imageUrl && React.createElement(Img, { src: imageUrl, alt: imageAlt }),
                renderVideoLinks())),
        React.createElement(FullscreenPopover, { isOpen: isPopoverOpen, onClose: handlePopoverClose },
            React.createElement("iframe", { width: "100%", height: "100%", src: videoUrl, title: "Introducing ThinkCopy", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true }))));
};
