import React, { useState, useEffect, useCallback, useMemo } from "react";
import { IconChevronDown } from "../svgIcons";
import { ImgV2 } from "../ImgV2/ImgV2";
const TabFaqAccordionCard = ({ id, question, icon, answer, setActiveCardId, activeCardId, omitFaq = false }) => {
    const handleExpand = useCallback(() => {
        if (setActiveCardId && id === activeCardId) {
            setActiveCardId(-1);
        }
        else if (setActiveCardId && id !== undefined) {
            setActiveCardId(id);
        }
    }, [activeCardId, id, setActiveCardId]);
    const [answerValue, setAnswerValue] = useState("");
    useEffect(() => {
        setAnswerValue(answer);
    }, [answer]);
    const answerElement = useCallback(() => React.createElement("div", { ...(!omitFaq && {
            itemProp: "text"
        }), className: "faq-accordion-list__faq-card-answer", dangerouslySetInnerHTML: { __html: answerValue } }), [answerValue, omitFaq]);
    return (React.createElement("div", { className: `faq-accordion-list__faq-card ${id === activeCardId ? "active" : ""}`, ...(!omitFaq && {
            itemScope: true,
            itemProp: "mainEntity",
            itemType: "https://schema.org/Question",
        }) },
        React.createElement("div", { className: "faq-accordion-list__faq-card-question", onClick: handleExpand },
            React.createElement("div", { className: "flex faq-accordion-list__plus-minus-text" },
                React.createElement("h5", { ...(!omitFaq && {
                        itemProp: "name"
                    }), className: "faq-accordion-list__title" },
                    icon && React.createElement(ImgV2, { width: 38, height: 38, src: icon, alt: question }),
                    question),
                React.createElement("span", { className: "faq-accordion-list__icon-close" }, IconChevronDown({})))),
        React.createElement("div", { className: "flex faq-accordion-list__plus-minus-text" },
            React.createElement("div", { ...(!omitFaq && {
                    itemScope: true,
                    itemProp: "acceptedAnswer",
                    itemType: "https://schema.org/Answer",
                }) }, answerElement()))));
};
export const TabWithAccordion = ({ title, subTitle, items, omitFaq = false }) => {
    const [activeCardId, setActiveCardId] = React.useState(-1);
    const accordion = useMemo(() => (React.createElement("div", { className: "faq-accordion-list__deck" }, items.map((item, i) => React.createElement(TabFaqAccordionCard, { key: `faq-accordion-list__card-${i}`, id: i, question: item.question, answer: item.answer, icon: item.icon, setActiveCardId: setActiveCardId, activeCardId: activeCardId, omitFaq: omitFaq })))), [items, setActiveCardId, activeCardId, omitFaq]);
    return (React.createElement("div", { className: "faq-accordion-list" },
        React.createElement("div", { ...(!omitFaq && {
                itemScope: true,
                itemType: "https://schema.org/FAQPage"
            }), className: "faq-accordion-list__column" },
            React.createElement("h3", { className: "faq-accordion-list__title" }, title),
            subTitle && React.createElement("div", { className: "faq-accordion-list__content", dangerouslySetInnerHTML: { __html: subTitle } }),
            accordion)));
};
