import React from "react";
import { Container, ContainerFW } from "../global";
import { ImgV2 } from "../ImgV2";
import { IconPDFDark } from "../svgIcons/pdfDark";
export const WideCardWithListV2 = ({ wideCardWithListCard1, wideCardWithListCard2, title }) => {
    const card = React.useCallback((widecardwithlistpropscard) => (React.createElement("div", null,
        React.createElement("div", { className: "wide-card-with-list-v2__subtitle p-regular color-dark-default" },
            React.createElement("h3", { className: "h3-semi-bold-small" }, widecardwithlistpropscard?.subtitle)),
        React.createElement("div", { className: "wide-card-with-list-v2" },
            React.createElement("div", { className: "color-dark-80 wide-card-with-list-v2__richtext1", dangerouslySetInnerHTML: {
                    __html: widecardwithlistpropscard?.text?.split("#####")[0] || "",
                } }),
            React.createElement("ol", { className: "wide-card-with-list-v2__list" }, widecardwithlistpropscard?.listItems?.map((item, i) => item ? (React.createElement("li", { key: `wide-card-with-list-v2-item-${i}` },
                React.createElement("div", { className: "wide-card-with-list-v2__gap-item1 flex" },
                    item.iconUrl && (React.createElement(ImgV2, { src: item.iconUrl, alt: item.iconAlt || "icon" })),
                    React.createElement("div", { className: "centered-container text-nav color-dark-default" }, item?.text)))) : React.createElement(React.Fragment, null))),
            React.createElement("div", { className: "color-dark-80 wide-card-with-list-v2__richtext2", dangerouslySetInnerHTML: {
                    __html: widecardwithlistpropscard?.text?.split("#####")[1] || "",
                } }),
            widecardwithlistpropscard?.linkUrl && widecardwithlistpropscard?.linkText && (React.createElement("div", { className: "wide-card-with-list-v2__link-container" },
                React.createElement("a", { href: widecardwithlistpropscard.linkUrl, className: "button button--secondary wide-card-with-list-v2__link" },
                    React.createElement("div", { className: "centered-container wide-card-with-list-v2__gap-item2 color-dark-default" },
                        IconPDFDark,
                        widecardwithlistpropscard.linkText))))))), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ContainerFW, { backgroundColor: "#fff" },
            React.createElement(Container, null,
                React.createElement("h2", { className: "wide-card-with-list-v2__title color-dark-default" }, title),
                React.createElement("div", { className: "wide-card-with-list-v2__top-level-flex" },
                    card(wideCardWithListCard1),
                    card(wideCardWithListCard2))))));
};
