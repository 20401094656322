import React from "react";
import { Heading } from "../../Heading";
import { Container, ContainerFW } from "../../global";
import { CardSwiper } from "./Cards/CardSwiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { getColors } from "../../../utils";
export const AddressCardDeck = ({ title, backGroundColor, cards }) => {
    const { bgColor } = getColors(backGroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "address-card-deck-root" },
                React.createElement(Heading, { design: "h3-semi-bold" }, title),
                React.createElement("div", { className: "address-card-deck-root__cardsWrapper" },
                    React.createElement(Swiper, { pagination: {
                            clickable: true,
                            renderBullet: () => ("<span class='address-card-deck__bullet swiper-pagination-bullet'></span>"),
                        }, breakpoints: {
                            375: {
                                slidesPerView: 1.05,
                                spaceBetween: 7
                            },
                            601: {
                                slidesPerView: cards.length,
                                spaceBetween: 0
                            },
                            1024: {
                                slidesPerView: cards.length,
                                spaceBetween: 12
                            },
                        } }, cards.map((card, i) => React.createElement(SwiperSlide, { key: `cards-deck-with-title-sub-swipe-slide-${i}` },
                        React.createElement(CardSwiper, { ...card })))))))));
};
