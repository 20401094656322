import React, { useState, useCallback, useEffect } from "react";
import { ContractSpecsFilterSet } from "./ContractSpecsFilterSet";
import { ToggleExpandCollapse } from "./ToggleExpandCollapse";
const toggleButtonsProps = {
    expandButtonProps: {
        buttonText: "Show filters"
    },
    collapseButtonProps: {
        buttonText: "Hide filters"
    }
};
export const ContractSpecsFilters = ({ countries, currencies, accountTypes, leverageTypes, positionQualities, instrumentSubtypes, iniActivePositionQuality, iniActiveCountry, iniActiveCurrency, iniActiveAccountType, iniActiveInstrumentSubtype, iniActiveLeverageType, onSearchChange, onPositionQualityChange, onCountryChange, onCurrencyChange, onAccountTypeChange, onLeverageTypeChange, onInstrumentSubtypeChange, onToggleFiltersExpand, onFiltersTransitionEnd, onResetAllFilters, mustResetFiltersInternally = false, areFiltersExpanded, showClearFiltersButton = true, filtersTranslations, filtersEmptyState }) => {
    const getActivePositionQuality = useCallback(() => iniActivePositionQuality || "All", [iniActivePositionQuality]);
    const getActiveCountry = useCallback(() => iniActiveCountry || "", [iniActiveCountry]);
    const getActiveCurrency = useCallback(() => iniActiveCurrency || "", [iniActiveCurrency]);
    const getActiveAccountType = useCallback(() => iniActiveAccountType || "", [iniActiveAccountType]);
    const getActiveInstrumentType = useCallback(() => {
        if (iniActiveInstrumentSubtype) {
            return iniActiveInstrumentSubtype;
        }
        return typeof instrumentSubtypes?.[0] === "string" ? instrumentSubtypes?.[0] : instrumentSubtypes?.[0]?.value;
    }, [iniActiveInstrumentSubtype, instrumentSubtypes]);
    const getActiveLeverageType = useCallback(() => {
        if (iniActiveLeverageType) {
            return iniActiveLeverageType;
        }
        return typeof leverageTypes?.[0] === "string" ? leverageTypes?.[0] : leverageTypes?.[0]?.value;
    }, [iniActiveLeverageType, leverageTypes]);
    const [search, setSearch] = useState("");
    const [activePositionQuality, setActivePositionQuality] = useState(getActivePositionQuality);
    const [activeCountry, setActiveCountry] = useState(getActiveCountry);
    const [activeCurrency, setActiveCurrency] = useState(getActiveCurrency);
    const [activeAccountType, setActiveAccountType] = useState(getActiveAccountType);
    const [activeInstrumentSubtype, setActiveInstrumentSubtype] = useState(getActiveInstrumentType);
    const [activeLeverageType, setActiveLeverageType] = useState(getActiveLeverageType);
    const searchHandler = React.useCallback((e) => {
        setSearch(e.target.value);
        onSearchChange && onSearchChange(e.target.value);
    }, [onSearchChange]);
    const handlePositionQualityChange = React.useCallback((value) => {
        setActivePositionQuality(value);
        onPositionQualityChange && onPositionQualityChange(value);
    }, [onPositionQualityChange]);
    const handleCountryChange = React.useCallback((countryCode) => {
        setActiveCountry(countryCode);
        onCountryChange && onCountryChange(countryCode);
    }, [onCountryChange]);
    const handleCurrencyChange = React.useCallback((currencyCode) => {
        setActiveCurrency(currencyCode);
        onCurrencyChange && onCurrencyChange(currencyCode);
    }, [onCurrencyChange]);
    const handleInstrumentSubtypeChange = useCallback((indexString) => {
        const instrumentSubtype = indexString || "";
        setActiveInstrumentSubtype(instrumentSubtype);
        onInstrumentSubtypeChange && onInstrumentSubtypeChange(instrumentSubtype);
    }, [onInstrumentSubtypeChange]);
    const handleAccountTypeChange = React.useCallback((accountType) => {
        setActiveAccountType(accountType);
        onAccountTypeChange && onAccountTypeChange(accountType);
    }, [onAccountTypeChange]);
    const handleLeverageChange = React.useCallback((leverageType) => {
        setActiveLeverageType(leverageType);
        onLeverageTypeChange && onLeverageTypeChange(leverageType);
    }, [onLeverageTypeChange]);
    const handleSearchInputEnterKeyUp = useCallback((e) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            e.target.blur();
        }
    }, []);
    useEffect(() => {
        if (!mustResetFiltersInternally) {
            return;
        }
        setActivePositionQuality(getActivePositionQuality());
        setActiveCountry(getActiveCountry());
        setActiveCurrency(getActiveCurrency());
        setActiveAccountType(getActiveAccountType());
        setActiveInstrumentSubtype(getActiveInstrumentType());
        setActiveLeverageType(getActiveLeverageType());
    }, [getActiveAccountType, getActiveCountry, getActiveCurrency, getActiveInstrumentType, getActiveLeverageType, getActivePositionQuality, mustResetFiltersInternally]);
    const hasRealFilters = !filtersEmptyState?.areRealFiltersEmpty;
    return (React.createElement("div", { className: "contractspecs-filters" },
        hasRealFilters && (React.createElement("div", { className: "contractspecs-filters__filter-bar-container" },
            React.createElement(ToggleExpandCollapse, { isDefaultExpanded: areFiltersExpanded, toggleButtonsProps: toggleButtonsProps, onToggleExpand: onToggleFiltersExpand, onTransitionEnd: onFiltersTransitionEnd },
                React.createElement("div", { className: "contractspecs-filters__filter-bar" },
                    React.createElement(ContractSpecsFilterSet, { positionQualities: positionQualities, countries: countries, currencies: currencies, accountTypes: accountTypes, leverageTypes: leverageTypes, instrumentSubtypes: instrumentSubtypes, activePositionQuality: activePositionQuality, activeCountry: activeCountry, activeCurrency: activeCurrency, activeAccountType: activeAccountType, activeInstrumentSubtype: activeInstrumentSubtype, activeLeverageType: activeLeverageType, onPositionQualityChange: handlePositionQualityChange, onCountryChange: handleCountryChange, onCurrencyChange: handleCurrencyChange, onAccountTypeChange: handleAccountTypeChange, onInstrumentSubtypeChange: handleInstrumentSubtypeChange, onLeverageTypeChange: handleLeverageChange, filtersTranslations: filtersTranslations })),
                React.createElement(ToggleExpandCollapse, { isOnlyToggledFromOutside: true, isDefaultExpanded: showClearFiltersButton, toggleViews: ["small", "medium", "large"], onToggleExpand: onToggleFiltersExpand, onTransitionEnd: onFiltersTransitionEnd },
                    React.createElement("button", { className: "contractspecs-filters__clear-filters-button", onClick: onResetAllFilters }, "Clear filters"))))),
        React.createElement("div", { className: "contractspecs-filters__search" },
            React.createElement("input", { onChange: searchHandler, onKeyUp: handleSearchInputEnterKeyUp, value: search, className: "search-banner__input", type: "text", placeholder: filtersTranslations?.searchText?.title }))));
};
