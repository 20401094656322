const DEFAULT_ADDINFO_KEY_MAP = {
    Forex: "Forex",
    Indices: "Indices",
    Energy: "Energy",
    Metals: "Metals",
    Crypto: "Crypto"
};
export const LEVERAGE_DATA_GROUPS = [
    {
        name: "uk",
        columnSuffix: "",
        cultures: ["en-GB"]
    },
];
export const DEFAULT_CS_PAGE_CONFIG = [
    {
        cultures: ["en-GB"],
        instrumentGroupsConfig: {
            Forex: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    positionQualities: { options: ["All", "Major", "Minor", "Exotic"], translationKey: "positionQualities" },
                    leverageTypes: { options: ["Retail", "Professional"], translationKey: "leverageTypes" },
                }),
                getDefaultFilterValues: () => ({
                    iniActivePositionQuality: "Major",
                    iniActiveLeverageType: "Retail",
                }),
                getAutoExecutedHandlers: () => ({
                    positionQuality: "Major",
                    leverageType: "Retail",
                }),
                columnsPerLeverageType: {
                    Retail: ["MaxLeverageRetail"],
                    Professional: ["MaxLeverageProfMT4AndMT5", "MaxLeverageProfThinkTrader"]
                }
            },
            Indices: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    leverageTypes: { options: ["Retail", "Professional"], translationKey: "leverageTypes" },
                }),
                getDefaultFilterValues: () => ({
                    iniActiveLeverageType: "Retail",
                }),
                getAutoExecutedHandlers: () => ({
                    leverageType: "Retail",
                }),
                columnsThatAllowHtml: ["TradingHours"]
            },
            Energy: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    instrumentSubtypes: { options: ["Metals", "Energy"], translationKey: "instrumentSubtypes" },
                    leverageTypes: { options: ["Retail", "Professional"], translationKey: "leverageTypes" },
                }),
                getDefaultFilterValues: () => ({
                    iniActiveInstrumentSubtype: "Metals",
                    iniActiveLeverageType: "Retail",
                }),
                getAutoExecutedHandlers: () => ({
                    instrumentSubtype: "Metals",
                    leverageType: "Retail",
                }),
                columnsThatAllowHtml: ["TradingHours"]
            },
            Crypto: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                }),
                getDefaultFilterValues: () => ({
                    iniActiveLeverageType: "Professional",
                }),
                getAutoExecutedHandlers: () => ({
                    leverageType: "Retail",
                }),
                columnsThatAllowHtml: ["TradingHours"]
            },
        },
        addInfoMap: DEFAULT_ADDINFO_KEY_MAP,
        accountTypeIds: undefined,
        columnsPerLeverageType: {
            Retail: ["MaxLeverageRetail"],
            Professional: ["MaxLeverageProf"]
        },
        leverageDataGroups: LEVERAGE_DATA_GROUPS
    },
];
