import React from "react";
import { Img } from "../../Img";
import { Container, ContainerFW } from "../../global";
export const TradeWithComponent3Items = ({ mainTitle, subTitle, sectionItems }) => {
    const renderAdditionalIcons = (item) => (React.createElement("div", { className: "flex3" },
        item.additionalIcon1 && (React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: item.additionalIcon1LinkUrl },
            React.createElement(Img, { src: item.additionalIcon1, className: "trade-with-component-3-items__platform-icon", alt: item.imageAlt }))),
        item.additionalIcon2 && (React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: item.additionalIcon2LinkUrl },
            React.createElement(Img, { src: item.additionalIcon2, className: "trade-with-component-3-items__platform-icon", alt: item.imageAlt }))),
        item.additionalIcon3 && (React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: item.additionalIcon3LinkUrl },
            React.createElement(Img, { src: item.additionalIcon3, className: "trade-with-component-3-items__platform-icon", alt: item.imageAlt })))));
    const renderSectionImageAndHeading = (item, headingClass) => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `trade-with-component-3-items__section-image ${headingClass}` },
            React.createElement(Img, { src: item.imageUrl, alt: item.imageAlt })),
        React.createElement("div", { className: `trade-with-component-3-items__section-heading hide-medium-ndb ${headingClass}` },
            React.createElement("h5", { className: "h5-bold color-dark-default" }, item.title))));
    const renderSectionItem = (item, index) => (React.createElement("div", { key: index, className: "trade-with-component-3-items__section" },
        React.createElement("div", { className: "hide-small-ndb hide-large-ndb flex2" },
            renderSectionImageAndHeading(item, ""),
            React.createElement("div", { className: "trade-with-component-3-items__section-heading3" },
                React.createElement("h5", { className: "h5-bold color-dark-default" }, item.title),
                React.createElement("div", { className: "color-dark-80", dangerouslySetInnerHTML: { __html: item.subTitle } }),
                item.additionalIcon1 && renderAdditionalIcons(item))),
        React.createElement("div", { className: "hide-medium-ndb" },
            renderSectionImageAndHeading(item, "trade-with-component-3-items__center-content"),
            React.createElement("div", { className: "trade-with-component-3-items__section-subtitle color-dark-80 trade-with-component-3-items__center-content" },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: item.subTitle } })),
            item.additionalIcon1 && (React.createElement("div", { className: "flex3 centered-container hide-large-ndb" }, renderAdditionalIcons(item))))));
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "trade-with-component-3-items" },
                mainTitle && React.createElement(React.Fragment, null,
                    React.createElement("h3", { className: "trade-with-component-3-items__main-title color-dark-default h3-bold hide-small-ndb hide-large-ndb" }, mainTitle),
                    React.createElement("h3", { className: "trade-with-component-3-items__main-title color-dark-default h3-bold centered-container hide-medium-ndb" }, mainTitle)),
                subTitle &&
                    React.createElement("p", { className: "centered-container" }, subTitle),
                React.createElement("div", { className: "trade-with-component-3-items__main-flex" }, sectionItems.length > 0 &&
                    sectionItems.map((item, index) => renderSectionItem(item, index)))))));
};
