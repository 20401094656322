import React, { useState, useEffect } from "react";
import { Container, ContainerFW } from "../global";
const RatioContainer = ({ leftContent, rightContent, ratio, changeToColumn }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const [leftRatio, rightRatio] = ratio.split(":").map(Number);
    const total = leftRatio + rightRatio;
    const getFlexValues = () => {
        const leftFlex = (leftRatio / total) * 100;
        const rightFlex = (rightRatio / total) * 100;
        return [leftFlex, rightFlex];
    };
    const [leftFlex, rightFlex] = getFlexValues();
    const dropToColumnsClass = windowWidth <= changeToColumn ? "ratio-container__column" : "";
    return (React.createElement(ContainerFW, { className: "g-container--new" },
        React.createElement(Container, { className: "g-container--new" },
            React.createElement("div", { className: `ratio-container ${dropToColumnsClass}` },
                React.createElement("div", { className: "ratio-container__left", style: { flex: `${leftFlex}%` } }, leftContent),
                React.createElement("div", { className: "ratio-container__right", style: { flex: `${rightFlex}%` } }, rightContent)))));
};
export default RatioContainer;
