import React from "react";
import { ContainerFW, Container } from "../global";
import { ImgV2 } from "../ImgV2";
import { Heading } from "../Heading";
import { ButtonV2 } from "../ButtonV2";
import { getColors } from "../../utils";
export const Mt5HeroBanner = ({ title, subtitle, imageUrl, ctaUrl, ctaLabel, linkUrl, linkLabel, backGroundColor, buttonDesign = "cta-large-wbg" }) => {
    const { bgColor } = getColors(backGroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "mt5-hero-banner__root" },
            React.createElement(Container, null,
                React.createElement("div", { className: "mt5-hero-banner__imageWrapper" },
                    React.createElement(ImgV2, { src: imageUrl })),
                React.createElement(Heading, { design: "h1-regular" }, title),
                React.createElement("p", { className: "mt5-hero-banner__subtitle", dangerouslySetInnerHTML: {
                        __html: subtitle || "",
                    } }),
                React.createElement(ButtonV2, { design: buttonDesign, href: ctaUrl }, ctaLabel),
                (linkLabel && linkUrl) && React.createElement("a", { className: "p-bold", href: linkUrl }, linkLabel)))));
};
