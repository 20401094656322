import React, { useMemo } from "react";
export const BigAwardsCard = ({ awardImgUrl, title, subTitle, year, organization, design, backgroundImg, topIconPosition, firstGradientColor, secondGradientColor, }) => {
    const imageUrlCss = backgroundImg ? `url(${backgroundImg})` : "none";
    const handleIconPosition = useMemo(() => {
        if (topIconPosition === "left") {
            return "0 auto auto 0";
        }
        else if (topIconPosition === "center") {
            return "0 auto auto";
        }
        return "0 0 auto auto";
    }, [topIconPosition]);
    return (React.createElement("section", { className: `big-awards-card${design ? ` big-awards-card--${design}` : ""}`, style: {
            "--background-image-url": imageUrlCss,
            "--first-gradient-color": firstGradientColor,
            "--second-gradient-color": secondGradientColor,
        } },
        React.createElement("img", { src: awardImgUrl, alt: organization, style: { margin: handleIconPosition } }),
        React.createElement("h3", null, title),
        React.createElement("div", null,
            React.createElement("span", { className: "big-awards-card__subtitle" }, subTitle),
            React.createElement("span", { className: "big-awards-card__year" }, year))));
};
