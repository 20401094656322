import React from "react";
import { Container, ContainerFW } from "../../global";
import { ImgV2 } from "../../ImgV2/ImgV2";
import { ButtonV2 } from "../../ButtonV2/ButtonV2";
import { getColors } from "../../../utils";
export const ThinkTraderCardWithImage = ({ title, description, imageUrl, imageAlt, imageSectionBackground, imagePosition, buttonUrl, buttonLabel, backgroundColor, buttonDesign, imgPositionUpOrDown, buttonPosition, buttonShowArrowRight, buttonBgColor, leftButtonImage, hoverLeftImage }) => {
    const { bgColor } = getColors(backgroundColor);
    const mainClassName = `${imagePosition === "left" ? "thinkTrader-card-with-image image-position-left" : "thinkTrader-card-with-image image-position-right"} ${imgPositionUpOrDown === "Up" ? "imageUP" : "imageDown"}`;
    let secondClassNAme;
    if (buttonPosition === "left") {
        secondClassNAme = "classButtonLeft";
    }
    else if (buttonPosition === "right") {
        secondClassNAme = "classButtonRight";
    }
    else {
        secondClassNAme = "classButtonCenter";
    }
    const textButtonSection = `thinkTrader-card-with-image__text-button-section ${secondClassNAme}`;
    const handleButtonColor = () => {
        if (buttonBgColor) {
            return buttonBgColor;
        }
        return "#E5EEEE";
    };
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: mainClassName },
                React.createElement("div", { className: textButtonSection },
                    React.createElement("div", { className: "thinkTrader-card-with-image__text-button-section__text-container" },
                        React.createElement("h2", { className: "thinkTrader-card-with-image__text-button-section__text-container__title-wrapper", dangerouslySetInnerHTML: { __html: title } }),
                        React.createElement("div", { className: "thinkTrader-card-with-image__text-button-section__text-container__desk-wrapper", dangerouslySetInnerHTML: { __html: description } })),
                    buttonLabel && React.createElement("div", { className: "thinkTrader-card-with-image__text-button-section__button-container" },
                        React.createElement(ButtonV2, { icon: leftButtonImage, iconHover: hoverLeftImage, design: buttonDesign, href: buttonUrl, showArrowRight: buttonShowArrowRight, style: { "--button-bg-color": `${handleButtonColor()}` } }, buttonLabel))),
                React.createElement("div", { className: "thinkTrader-card-with-image__image-section", style: { backgroundColor: imageSectionBackground } },
                    React.createElement(ImgV2, { src: imageUrl, alt: imageAlt, loading: "eager" }))))));
};
