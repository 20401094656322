import React from "react";
export const CardsDeckInfoBox = ({ text, title, className }) => (React.createElement("div", { className: `cards-deck-info-box ${className ? ` ${className}` : ""}` },
    React.createElement("div", { className: "cards-deck-info-box__inner" },
        React.createElement("div", { className: "cards-deck-info-box__content" },
            title && React.createElement("h4", { className: "cards-deck-info-box__title", dangerouslySetInnerHTML: {
                    __html: title,
                } }),
            React.createElement("div", { className: "cards-deck-info-box__text", dangerouslySetInnerHTML: {
                    __html: text,
                } })))));
