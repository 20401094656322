import { getAccountTypes, mustShowLeverageFilter } from "../utils/tabInitialization.utils";
const ACCOUNT_TYPE_IDS_TO_NAMES = {
    1: "Standard",
    2: "ThinkZero",
    3: "Mini",
    4: "ThinkTrader",
};
export const ACCOUNT_TYPES_PER_CULTURE = [
    {
        cultures: ["en-EU", "en-AU", "en-GB", "es-ES", "de-DE"],
        accTypesIdsPerInstrGroup: {
            Forex: [1, 2, 4],
            Indices: [1, 2, 4],
            Energy: [1, 2, 4],
            Metals: [1, 2, 4],
            "ETFs TT & MT5": [1, 2, 4],
            "Shares TT & MT5": [1, 2, 4],
            "Shares MT4": [1, 2, 4],
            Futures: [1, 2],
            Crypto: [1, 2, 4]
        }
    },
    {
        cultures: ["ar-AE", "zh-TW", "en-KY", "en-ZA", "en-US", "it-IT", "pt-BR", "es-CO", "th-TH", "vi-VN"],
        accTypesIdsPerInstrGroup: {
            Forex: [1, 2, 4],
            Indices: [1, 2, 4],
            Energy: [1, 2, 4],
            Metals: [1, 2, 4],
            "ETFs TT & MT5": [1, 2, 4],
            "Shares TT & MT5": [1, 2, 4],
            "Shares MT4": [1, 2, 4],
            Futures: [1, 2],
            Crypto: [1, 2, 4]
        },
    },
    {
        cultures: ["en-NZ", "en-AE"],
        accTypesIdsPerInstrGroup: {
            Forex: [4],
            Indices: [4],
            Energy: [4],
            Metals: [4],
            "ETFs TT & MT5": [4],
            "Shares TT & MT5": [4],
            "Shares MT4": [4],
            Futures: [4],
            Crypto: [4]
        }
    },
];
const DEFAULT_ADDINFO_KEY_MAP = {
    Forex: "Forex",
    Indices: "Indices",
    Energy: "Energy",
    Metals: "Metals",
    "ETFs TT & MT5": "TT & MT5",
    "Shares TT & MT5": "TT & MT5",
    "Shares MT4": "MT4",
    Futures: "Futures",
    Crypto: "Crypto"
};
export const LEVERAGE_DATA_GROUPS = [
    {
        name: "generalGroup",
        columnSuffix: "",
        cultures: ["en-US", "en-ZA", "en-KY", "es-CO", "pt-BR", "th-TH", "zh-TW", "ar-AE", "vi-VN"],
        specialCase: {
            target: "instrumentGroup",
            specialCaseInstrumentGroups: ["ETFs", "Shares"],
            correctionColumn: "MaxLeverageProfTraders__EU"
        }
    },
    {
        name: "eu",
        columnSuffix: "__EU",
        cultures: ["en-GB", "en-AU", "en-NZ", "en-AE", "en-EU", "es-ES", "de-DE"],
        specialCase: {
            target: "culture",
            specialCaseCultures: ["en-AU"],
        },
        instrumentGroups: undefined
    },
];
export const DEFAULT_CS_PAGE_CONFIG = (culture) => [
    {
        cultures: ["ar-AE", "zh-TW", "en-AE", "en-AU", "en-KY", "en-EU", "en-NZ", "en-ZA", "en-US", "de-DE", "it-IT", "pt-BR", "es-CO", "es-ES", "th-TH", "vi-VN"],
        instrumentGroupsConfig: {
            Forex: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    positionQualities: { options: ["All", "Major", "Minor", "Exotic"], translationKey: "positionQualities" },
                    accountTypes: { options: getAccountTypes(culture, "Forex", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    leverageTypes: mustShowLeverageFilter(culture, LEVERAGE_DATA_GROUPS) ? { options: ["Retail", "Professional"], translationKey: "leverageTypes" } : undefined
                }),
                getDefaultFilterValues: () => ({
                    iniActivePositionQuality: "Major",
                    iniActiveAccountType: "Standard",
                    iniActiveLeverageType: "Retail"
                }),
                getAutoExecutedHandlers: () => ({
                    positionQuality: "Major",
                    accountType: "Standard",
                    leverageType: "Retail",
                })
            },
            Indices: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "Indices", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    leverageTypes: mustShowLeverageFilter(culture, LEVERAGE_DATA_GROUPS) ? { options: ["Retail", "Professional"], translationKey: "leverageTypes" } : undefined
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveLeverageType: "Retail"
                }),
                getAutoExecutedHandlers: () => ({
                    accountType: "Standard",
                    leverageType: "Retail",
                }),
                columnsThatAllowHtml: ["TradingHours"]
            },
            Energy: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "Energy", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    instrumentSubtypes: { options: ["Metals", "Energy"], translationKey: "instrumentSubtypes" },
                    leverageTypes: mustShowLeverageFilter(culture, LEVERAGE_DATA_GROUPS) ? { options: ["Retail", "Professional"], translationKey: "leverageTypes" } : undefined
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveInstrumentSubtype: "Metals",
                    iniActiveLeverageType: "Retail"
                }),
                getAutoExecutedHandlers: () => ({
                    accountType: "Standard",
                    instrumentSubtype: "Metals",
                    leverageType: "Retail",
                }),
                columnsThatAllowHtml: ["TradingHours"]
            },
            "ETFs TT & MT5": {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "ETFs TT & MT5", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    countries: { options: ["US", "SA", "AU"], translationKey: "countries" },
                    currencies: { options: ["USD", "AUD", "ZAR"], translationKey: "currencies" },
                    leverageTypes: mustShowLeverageFilter(culture, LEVERAGE_DATA_GROUPS) ? { options: ["Retail", "Professional"], translationKey: "leverageTypes" } : undefined
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveInstrumentSubtype: "ETFs TT & MT5",
                    iniActiveLeverageType: "Retail"
                }),
                getAutoExecutedHandlers: () => ({
                    country: "",
                    currency: "",
                    accountType: "Standard",
                    instrumentSubtype: "ETFs TT & MT5",
                    leverageType: "Retail",
                }),
            },
            "Shares TT & MT5": {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "Shares TT & MT5", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    countries: { options: ["US", "SA", "HK", "AU"], translationKey: "countries" },
                    currencies: { options: ["USD", "AUD", "HKD", "ZAR"], translationKey: "currencies" },
                    instrumentSubtypes: { options: ["Shares TT & MT5", "Shares MT4"], translationKey: "sharesPlatform" },
                    leverageTypes: mustShowLeverageFilter(culture, LEVERAGE_DATA_GROUPS) ? { options: ["Retail", "Professional"], translationKey: "leverageTypes" } : undefined
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveInstrumentSubtype: "Shares TT & MT5",
                    iniActiveLeverageType: "Retail"
                }),
                getAutoExecutedHandlers: () => ({
                    country: "",
                    currency: "",
                    accountType: "Standard",
                    instrumentSubtype: "Shares TT & MT5",
                    leverageType: "Retail",
                }),
            },
            Futures: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "Futures", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    leverageTypes: mustShowLeverageFilter(culture, LEVERAGE_DATA_GROUPS) ? { options: ["Retail", "Professional"], translationKey: "leverageTypes" } : undefined
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveLeverageType: "Retail"
                }),
                getAutoExecutedHandlers: () => ({
                    accountType: "Standard",
                    leverageType: "Retail",
                }),
                columnsThatAllowHtml: ["Instrument"]
            },
            Crypto: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "Crypto", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    leverageTypes: mustShowLeverageFilter(culture, LEVERAGE_DATA_GROUPS) ? { options: ["Retail", "Professional"], translationKey: "leverageTypes" } : undefined
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveLeverageType: "Retail"
                }),
                getAutoExecutedHandlers: () => ({
                    accountType: "Standard",
                    leverageType: "Retail",
                })
            },
        },
        addInfoMap: DEFAULT_ADDINFO_KEY_MAP,
        accountTypeIds: ACCOUNT_TYPE_IDS_TO_NAMES,
        columnsPerLeverageType: {
            Retail: ["MaxLeverageRetailTraders"],
            Professional: ["MaxLeverageProfTraders"]
        },
        leverageDataGroups: LEVERAGE_DATA_GROUPS,
    },
    {
        cultures: ["en-GB"],
        instrumentGroupsConfig: {
            Forex: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    positionQualities: { options: ["All", "Major", "Minor", "Exotic"], translationKey: "positionQualities" },
                    accountTypes: { options: getAccountTypes(culture, "Forex", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    leverageTypes: { options: ["Retail", "Professional"], translationKey: "leverageTypes" },
                }),
                getDefaultFilterValues: () => ({
                    iniActivePositionQuality: "Major",
                    iniActiveAccountType: "Standard",
                    iniActiveLeverageType: "Retail"
                }),
                getAutoExecutedHandlers: () => ({
                    positionQuality: "Major",
                    accountType: "Standard",
                    leverageType: "Retail"
                })
            },
            Indices: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "Indices", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    leverageTypes: { options: ["Retail", "Professional"], translationKey: "leverageTypes" },
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveLeverageType: "Retail",
                }),
                getAutoExecutedHandlers: () => ({
                    accountType: "Standard",
                    leverageType: "Retail"
                })
            },
            Energy: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "Energy", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    instrumentSubtypes: { options: ["Metals", "Energy"], translationKey: "instrumentSubtypes" },
                    leverageTypes: { options: ["Retail", "Professional"], translationKey: "leverageTypes" },
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "ThinkTrader",
                    iniActiveInstrumentSubtype: "Metals",
                    iniActiveLeverageType: "Retail",
                }),
                getAutoExecutedHandlers: () => ({
                    accountType: "Standard",
                    instrumentSubtype: "Metals",
                    leverageType: "Retail",
                })
            },
            "ETFs TT & MT5": {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "ETFs TT & MT5", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    countries: { options: ["US", "SA", "AU"], translationKey: "countries" },
                    currencies: { options: ["USD", "AUD", "ZAR"], translationKey: "currencies" },
                    leverageTypes: { options: ["Retail", "Professional"], translationKey: "leverageTypes" },
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveInstrumentSubtype: "ETFs TT & MT5",
                    iniActiveLeverageType: "Retail",
                }),
                getAutoExecutedHandlers: () => ({
                    country: "",
                    currency: "",
                    accountType: "Standard",
                    instrumentSubtype: "ETFs TT & MT5",
                    leverageType: "Retail",
                }),
            },
            "Shares TT & MT5": {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "Shares TT & MT5", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    countries: { options: ["US", "SA", "HK", "AU"], translationKey: "countries" },
                    currencies: { options: ["USD", "AUD", "HKD", "ZAR"], translationKey: "currencies" },
                    leverageTypes: { options: ["Retail", "Professional"], translationKey: "leverageTypes" },
                    instrumentSubtypes: { options: ["Shares TT & MT5", "Shares MT4"], translationKey: "sharesPlatform" }
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveInstrumentSubtype: "Shares TT & MT5",
                    iniActiveLeverageType: "Retail",
                }),
                getAutoExecutedHandlers: () => ({
                    country: "",
                    currency: "",
                    accountType: "Standard",
                    instrumentSubtype: "Shares TT & MT5",
                    leverageType: "Retail",
                }),
            },
            Futures: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: { options: getAccountTypes(culture, "Futures", ACCOUNT_TYPES_PER_CULTURE, ACCOUNT_TYPE_IDS_TO_NAMES), translationKey: "accountTypes" },
                    leverageTypes: { options: ["Retail", "Professional"], translationKey: "leverageTypes" },
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveLeverageType: "Retail",
                }),
                getAutoExecutedHandlers: () => ({
                    accountType: "Standard",
                    leverageType: "Retail",
                }),
            },
            Crypto: {
                getRelevantData: undefined,
                getRelevantFilters: (filterProps) => ({
                    searchText: { translationKey: "searchText" },
                    accountTypes: undefined,
                    leverageTypes: undefined,
                }),
                getDefaultFilterValues: () => ({
                    iniActiveAccountType: "Standard",
                    iniActiveLeverageType: "Professional",
                }),
                getAutoExecutedHandlers: () => ({
                    accountType: "Standard",
                    leverageType: "Professional",
                })
            },
        },
        addInfoMap: DEFAULT_ADDINFO_KEY_MAP,
        accountTypeIds: ACCOUNT_TYPE_IDS_TO_NAMES,
        columnsPerLeverageType: {
            Retail: ["MaxLeverageRetailTraders"],
            Professional: ["MaxLeverageProfTraders"]
        },
        leverageDataGroups: LEVERAGE_DATA_GROUPS
    }
];
