import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { ContainerFW, Container, Col, Row } from "../global";
import { fetchData } from "./fetchData/fetchData";
import { JobBoardPagination } from "./JobBoardPagination/JobBoardPagination";
import { debounce } from "debounce";
import { pageViewStyle } from "../../pageViewStyle/pageViewStyle";
import locationIcon from "../svgIcons/Location";
import timeClock from "../svgIcons/timeClock";
import coloredUserIcon from "../svgIcons/User";
export const JobBoard = (props) => {
    const [jobs, setJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const departments = Array.from(new Set(["All", ...jobs.map(job => job.departments[0].name)]));
    const offices = Array.from(new Set(["All", ...jobs.map(job => job.offices[0].name)]));
    const [search, setSearch] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState("All");
    const [selectedOffice, setSelectedOffice] = useState("All");
    const [errorMessage, setErrorMessage] = useState("");
    const [view, setView] = useState(pageViewStyle.getView());
    const onViewUpdate = (view) => {
        setView(view);
    };
    const jobsPerPage = useMemo(() => (view !== "large" ? 6 : 10), [view]);
    useEffect(() => {
        pageViewStyle.subscribe(onViewUpdate);
        return () => {
            pageViewStyle.unsubscribe(onViewUpdate);
        };
    }, []);
    useEffect(() => {
        void fetchData("https://boards-api.greenhouse.io/v1/boards/thinkmarkets/jobs?content=true", 100, setJobs, setErrorMessage);
    }, []);
    const filteredJobs = jobs.filter((job) => job.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
        (selectedOffice === "All"
            ? true
            : job.offices[0].name === selectedOffice) &&
        (selectedDepartment === "All"
            ? true
            : job.departments[0].name === selectedDepartment));
    const selectedJobs = filteredJobs.slice(currentPage * jobsPerPage, (currentPage + 1) * jobsPerPage);
    const numOfPages = Math.ceil(filteredJobs.length / jobsPerPage);
    const setSearchDebounced = debounce(setSearch, 20);
    const searchJobs = useCallback(function (event) {
        setCurrentPage(0);
        return setSearchDebounced(event.currentTarget.value);
    }, []);
    const searchDepartment = useCallback(function (event) {
        setCurrentPage(0);
        return setSelectedDepartment(event.currentTarget.value);
    }, []);
    const searchCity = useCallback(function (event) {
        setCurrentPage(0);
        return setSelectedOffice(event.currentTarget.value);
    }, []);
    const thisComponentRef = useRef(null);
    const scrollToThisComponentRef = useCallback(() => {
        thisComponentRef.current?.scrollIntoView({ behavior: "auto" });
        if (thisComponentRef.current) {
            window.scrollBy(0, -105);
        }
    }, []);
    const mounted = React.useRef(false);
    React.useEffect(() => {
        if (mounted.current) {
            scrollToThisComponentRef();
        }
        else {
            mounted.current = true;
        }
    }, [currentPage]);
    const handleCardClick = (url) => () => (window.open(url, "_blank"));
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { ref: thisComponentRef, className: "jobBoard", id: "jobBoard" },
            React.createElement(Container, null,
                React.createElement("div", { className: "jobBoard__content" },
                    React.createElement("h2", null, props.title),
                    React.createElement("div", { className: "jobBoard__searchFields" },
                        React.createElement("div", { className: "jobBoard__fieldItem" },
                            React.createElement("input", { type: "search", placeholder: "Search Position", value: search, onChange: searchJobs, className: "jobBoard__fieldItem__search" })),
                        React.createElement("div", { className: "jobBoard__fieldItem" },
                            React.createElement("label", { htmlFor: "location" }, "Location"),
                            React.createElement("select", { value: selectedOffice, placeholder: "Select Office", onChange: searchCity, className: "jobBoard__fieldItem__searchCity", id: "location" }, offices.map((office) => (React.createElement("option", { value: office, key: office }, office))))),
                        React.createElement("div", { className: "jobBoard__fieldItem" },
                            React.createElement("label", { htmlFor: "department" }, "Department"),
                            React.createElement("select", { value: selectedDepartment, placeholder: "Select Department", onChange: searchDepartment, id: "department" }, departments.map((department) => (React.createElement("option", { value: department, key: department }, department)))))),
                    React.createElement("div", { className: "jobBoard__list" },
                        errorMessage ? (React.createElement("h5", { className: "jobBoard__error" }, errorMessage)) : (React.createElement(Row.Cols2, null, selectedJobs.map((job) => (React.createElement(Col, { key: job.id },
                            React.createElement("div", { className: "jobBoard__card", onClick: handleCardClick(job.absolute_url) },
                                React.createElement("p", null, job.departments[0].name),
                                React.createElement("h6", null, job.title),
                                React.createElement("div", { className: "jobBoard__info" },
                                    React.createElement("div", { className: "jobBoard__detail" },
                                        locationIcon({}),
                                        React.createElement("p", null, job.offices[0].name)),
                                    React.createElement("div", { className: "jobBoard__detail" },
                                        timeClock({}),
                                        React.createElement("p", null, job.metadata[0].value)),
                                    React.createElement("div", { className: "jobBoard__detail" },
                                        coloredUserIcon({}),
                                        React.createElement("p", null, job.metadata[1].value))))))))),
                        (selectedJobs.length === 0 && !errorMessage) && React.createElement("h5", { className: "jobBoard__noMatch" }, "No matching positions available.")),
                    React.createElement("div", { className: "jobBoard__pagination" }, selectedJobs.length > 0 && React.createElement(JobBoardPagination, { currentPage: currentPage, numOfPages: numOfPages, setCurrentPage: setCurrentPage, isSmallScreen: view !== "large" })))))));
};
