import React from "react";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
export const ExperienceNewWayToTrade = ({ buttonLabel, buttonUrl, leftIcon, mainTitle, rightIcon, subTitle, bgImageMobile, bgImageDesktop }) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "experience-new-way-trade" },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement(Heading, { design: "h2-lp" }, mainTitle),
            React.createElement("div", { className: "experience-new-way-trade__container" },
                React.createElement("div", { className: "logos-wrapper" },
                    React.createElement("div", { className: "first-images-wrapper" },
                        React.createElement(ImgV2, { src: leftIcon })),
                    React.createElement("div", { className: "second-images-wrapper" },
                        React.createElement(ImgV2, { src: rightIcon }))),
                React.createElement("p", { className: "experience-new-way-trade__subtitle" }, subTitle),
                React.createElement(ButtonV2, { classes: "experience-new-way-trade__button", design: "secondary-large-wbg", href: buttonUrl }, buttonLabel),
                React.createElement("div", { className: "experience-new-way-trade__imageContainer" },
                    React.createElement(ImgV2, { smallUrl: bgImageMobile, mediumUrl: bgImageDesktop, largeUrl: bgImageDesktop, strictRender: true })))))));
