import React from "react";
import { getColors } from "../../utils";
import { Container, ContainerFW } from "../global";
import { SectionItem } from "./SectionItem/SectionItem";
export const SectionWithTwoLists = ({ columns, backGroundColor }) => {
    const { bgColor } = getColors(backGroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "section-with-two-lists" }, columns.length > 0 && columns.map((a) => (React.createElement("div", { key: `unique${a.content}` },
                React.createElement(SectionItem, { key: `unique${a.content}`, ...a }))))))));
};
