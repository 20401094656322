export const checkIsCorrectLeverageColumn = ({ columnName, leverageType, columnsPerLeverageType, culture, leverageDataGroups, isStrictCheck }) => {
    const columnNameLessAnyCulture = columnName.split("__")[0];
    const isSuffixlessColumnName = columnName === columnNameLessAnyCulture;
    const possibleLeverageColumn = Object.values(columnsPerLeverageType).find(listOfColumns => listOfColumns.includes(columnNameLessAnyCulture));
    if (!possibleLeverageColumn) {
        return isStrictCheck ? false : true;
    }
    const leverageGroupSuffix = leverageDataGroups?.find(leverageDataGroup => leverageDataGroup.cultures.includes(culture))?.columnSuffix;
    if (isSuffixlessColumnName) {
        if (!leverageGroupSuffix) {
            return columnsPerLeverageType[leverageType].includes(columnName);
        }
        else {
            return false;
        }
    }
    if (!leverageGroupSuffix) {
        return columnsPerLeverageType[leverageType].includes(columnName);
    }
    else {
        return columnsPerLeverageType[leverageType].includes(columnNameLessAnyCulture) && columnName === columnNameLessAnyCulture + leverageGroupSuffix;
    }
};
export const getCultureSpecialCaseColumn = (specialCase, culture, leverageType, columnsPerLeverageType) => {
    if (specialCase.target !== "culture") {
        return;
    }
    if (specialCase.specialCaseCultures.includes(culture)) {
        const possibleLeverageColumn = Object.values(columnsPerLeverageType[leverageType])[0];
        if (possibleLeverageColumn) {
            return `${possibleLeverageColumn}__${culture.split("-")[1].toUpperCase()}`;
        }
    }
    return;
};
export const getInstrGroupSpecialCaseColumn = (specialCase, instrumentGroupsAndSubgroups, instrumentGroupOrSubgroup) => {
    if (specialCase.target !== "instrumentGroup") {
        return;
    }
    const groupNamesToLookFor = getGroupsAndSubgroupsByMember(instrumentGroupsAndSubgroups, instrumentGroupOrSubgroup);
    if (groupNamesToLookFor) {
        if (groupNamesToLookFor.some(value => specialCase.specialCaseInstrumentGroups.includes(value))) {
            return specialCase.correctionColumn;
        }
    }
    return;
};
export const getSpecialCaseLeverageColumn = ({ leverageType, instrumentGroupOrSubgroup, instrumentGroupsAndSubgroups, columnsPerLeverageType, culture, leverageDataGroups }) => {
    const possibleSpecialLeverageCase = leverageDataGroups?.find(leverageDataGroup => leverageDataGroup.cultures.includes(culture) && leverageDataGroup.specialCase)?.specialCase;
    if (!possibleSpecialLeverageCase) {
        return;
    }
    if (possibleSpecialLeverageCase.target === "culture") {
        const cultureSpecialCaseColumn = getCultureSpecialCaseColumn(possibleSpecialLeverageCase, culture, leverageType, columnsPerLeverageType);
        if (cultureSpecialCaseColumn) {
            return cultureSpecialCaseColumn;
        }
    }
    if (possibleSpecialLeverageCase.target === "instrumentGroup") {
        const instrGroupSpecialCaseColumn = getInstrGroupSpecialCaseColumn(possibleSpecialLeverageCase, instrumentGroupsAndSubgroups, instrumentGroupOrSubgroup);
        if (instrGroupSpecialCaseColumn) {
            return instrGroupSpecialCaseColumn;
        }
    }
    return;
};
export const getEnhancedTableInfo = ({ tableColumns, leverageType, instrumentGroupOrSubgroup, instrumentGroupsAndSubgroups, columnsPerLeverageType, culture, leverageDataGroups }) => tableColumns.map(columnInfo => {
    const isLeverageColumn = checkIsCorrectLeverageColumn({ columnName: columnInfo.dataColumnName, leverageType, instrumentGroupOrSubgroup, columnsPerLeverageType, culture, leverageDataGroups, isStrictCheck: true });
    const specialCaseColumn = isLeverageColumn && getSpecialCaseLeverageColumn({ leverageType, instrumentGroupOrSubgroup, instrumentGroupsAndSubgroups, columnsPerLeverageType, culture, leverageDataGroups }) || undefined;
    return {
        ...columnInfo,
        isLeverageColumn,
        specialCaseColumn
    };
});
const extractInstrumentGroupData = ({ instrumentGroupName, instrumentSubgroupName }, data) => {
    const temp = data.filter((item) => item.instrumentGroup?.toLowerCase() ===
        instrumentSubgroupName?.toLowerCase() ||
        item.instrumentGroup?.toLowerCase() === instrumentGroupName.toLowerCase());
    return temp;
};
const getDataForCulture = (data, _culture) => data;
const getGroupConfigKeyName = ({ instrumentGroupName, instrumentSubgroupName }) => (instrumentSubgroupName || instrumentGroupName).toLowerCase();
export const getCultureConfigData = (culture, pageConfig) => pageConfig.find(cultureConfig => cultureConfig.cultures.map(cultureName => cultureName.toLowerCase()).includes(culture.toLowerCase()));
export const getInstrumentGroupConfigData = ({ instrumentGroupName, instrumentSubgroupName }, cultureConfig) => {
    const instrumentGroupKey = getGroupConfigKeyName({ instrumentGroupName, instrumentSubgroupName });
    const instrumentGroupConfigData = cultureConfig && Object.entries(cultureConfig.instrumentGroupsConfig)?.find(([instrumentGroupConfigKey,]) => instrumentGroupConfigKey.toLowerCase() === instrumentGroupKey)?.[1];
    if (!instrumentGroupConfigData) {
        console.info(`No instrument group config data could be found for subgroup '${instrumentSubgroupName}' in group '${instrumentGroupName}'`);
    }
    return instrumentGroupConfigData;
};
export const getRelevantData = (culture, instrumentGroupName, instrumentSubgroupName, instrumentGroupConfig, inputData) => {
    const dataPerCulture = getDataForCulture(inputData, culture);
    const extractedData = extractInstrumentGroupData({ instrumentGroupName, instrumentSubgroupName }, [...dataPerCulture]);
    const defaultData = instrumentGroupConfig?.getRelevantData?.(extractedData, instrumentSubgroupName, instrumentGroupName) || extractedData;
    return defaultData;
};
export const getRelevantFilters = (instrumentGroupConfig) => instrumentGroupConfig?.getRelevantFilters?.() || {};
export const getDefaultFilterValues = (instrumentGroupConfig) => instrumentGroupConfig?.getDefaultFilterValues?.() || {};
export const getAutoExecutedHandlers = (instrumentGroupConfig) => instrumentGroupConfig?.getAutoExecutedHandlers?.() || {};
export const getAccountTypeIds = (cultureConfig) => cultureConfig.accountTypeIds;
export const getColumnsPerLeverageType = (cultureConfig, instrumentGroupConfig) => instrumentGroupConfig?.columnsPerLeverageType || cultureConfig.columnsPerLeverageType;
export const getColumnsThatAllowHtml = (cultureConfig, instrumentGroupConfig) => instrumentGroupConfig?.columnsThatAllowHtml || cultureConfig?.columnsThatAllowHtml || [];
export const checkColumnExistsForAccountType = (accountTypes, accountTypeName, accountTypeIds) => {
    if (!accountTypeIds) {
        return false;
    }
    const columnAccTypesArray = accountTypes.split("|");
    const accountTypeId = Object.entries(accountTypeIds)?.find(([, accountName]) => accountName.toLowerCase() === accountTypeName.toLocaleLowerCase())?.[0];
    return columnAccTypesArray.includes(String(accountTypeId));
};
export const getAdditionInfoMap = (cultureConfig) => cultureConfig.addInfoMap || {};
export const getAdditionaInfo = (cultureConfig, localInfoKey, additionalInfos) => {
    if (!cultureConfig) {
        return;
    }
    const addInfoKey = getAdditionInfoMap(cultureConfig)?.[localInfoKey];
    return additionalInfos.find(item => item.group === addInfoKey);
};
export const capitalizeString = (string) => {
    const t = string.charAt(0).toUpperCase() + string.slice(1);
    return t;
};
export const decapitalizeString = (string) => {
    const t = string.charAt(0).toLowerCase() + string.slice(1);
    return t;
};
export const groomInitFilterName = (filterName) => decapitalizeString(filterName.replace("iniActive", ""));
export const getGroupFiltersTranslations = (filters, filtersTranslations) => Object.entries(filters).reduce((accumulatedObject, currentFilter) => {
    const [filterName, filterConfig] = currentFilter;
    const translationKey = filterConfig?.translationKey;
    const translatedTitle = (translationKey && filtersTranslations?.[translationKey]?.title) || filterName;
    const translatedOptions = (translationKey && filtersTranslations?.[translationKey]?.options) || filterConfig?.options;
    return ({
        ...accumulatedObject,
        [filterName]: {
            title: translatedTitle,
            options: translatedOptions
        }
    });
}, {});
export const getAccountTypes = (culture, instrumentGroup, accountTypesPerCulture, accountTypeIdsToNames) => {
    const accountIds = accountTypesPerCulture.find(culturesSettings => culturesSettings.cultures.includes(culture))?.accTypesIdsPerInstrGroup[instrumentGroup] || [];
    return accountIds.map((accountId) => accountTypeIdsToNames[accountId]);
};
export const mustShowLeverageFilter = (cultureCode, leverageDataGroupsConfig) => leverageDataGroupsConfig.find(group => group.name.toLowerCase() === "eu")?.cultures.includes(cultureCode);
export const getGroupsAndSubgroupsByMember = (instrumentGroupsAndSubgroups, groupOrSubgroup) => {
    const possibleGroupsAndSubgroups = Object.entries(instrumentGroupsAndSubgroups).find(([, groupingMembers]) => groupingMembers.includes(groupOrSubgroup));
    if (!possibleGroupsAndSubgroups) {
        return;
    }
    return possibleGroupsAndSubgroups[1];
};
export const getPossibleReassignMap = (reassignMapConfig, instrumentGroupsAndSubgroups, culture, instrumentGroupOrSubgroup) => {
    const configMap = reassignMapConfig[culture];
    if (!configMap) {
        return;
    }
    const namesToLookFor = getGroupsAndSubgroupsByMember(instrumentGroupsAndSubgroups, instrumentGroupOrSubgroup);
    if (!namesToLookFor) {
        return;
    }
    const reassignMap = {};
    configMap.forEach(entry => {
        if (!namesToLookFor.includes(entry.groupOrSubgroup)) {
            return;
        }
        entry.instrumentCodes.forEach(code => {
            reassignMap[code] = {
                propertyName: entry.propertyName,
                newValue: entry.newValue
            };
        });
    });
    return reassignMap;
};
export const getGroomedData = (data, reassignMapConfig, instrumentGroupsAndSubgroups, culture, instrumentGroupOrSubgroup) => {
    const reassignMap = getPossibleReassignMap(reassignMapConfig, instrumentGroupsAndSubgroups, culture, instrumentGroupOrSubgroup);
    if (!reassignMap) {
        return data;
    }
    const groomedData = [...data].map(dataItem => {
        const reassingEntry = dataItem.instrument && reassignMap[dataItem.instrument];
        if (reassingEntry) {
            return {
                ...dataItem,
                [reassingEntry.propertyName]: reassingEntry.newValue
            };
        }
        return dataItem;
    });
    return groomedData;
};
export const getFiltersEmptyState = (filters) => {
    const filterEntries = Object.entries(filters);
    const nonEmptyRealFiltersList = [];
    const emptyRealFiltersList = [];
    let searchTextEmptyState = true;
    const getEmptyState = (filterKey, filterValues) => {
        if (filterKey === "searchText") {
            searchTextEmptyState = !filterValues;
        }
        else {
            const isValuesArray = Array.isArray(filterValues);
            if ((!isValuesArray && filterValues) || (isValuesArray && filterValues.length)) {
                nonEmptyRealFiltersList.push(filterKey);
            }
            else {
                emptyRealFiltersList.push(filterKey);
            }
        }
    };
    for (let i = 0; i < filterEntries.length; i++) {
        const [filterKey, filterValues] = filterEntries[i];
        getEmptyState(filterKey, filterValues);
    }
    return {
        areAllFiltersEmpty: nonEmptyRealFiltersList.length === 0 && searchTextEmptyState,
        areRealFiltersEmpty: nonEmptyRealFiltersList.length === 0,
        isSearchEmpty: searchTextEmptyState,
        emptyList: emptyRealFiltersList,
        nonEmptyList: nonEmptyRealFiltersList
    };
};
